import Context from "./Context";
import { useContext, useState } from "react";

function Provider({ children }) {
  const [timer, setTimer] = useState();
  const [courseName, setCourseName] = useState("");
  const [courseList, setCourseList] = useState([""]);
  const [courseDescription, setCourseDescription] = useState("");
  const [lesson, setLesson] = useState([""]);
  const [theme, setTheme] = useState();
  const [lessonName, setLessonName] = useState("");
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [isFocusAnnotation, setIsFocusAnnotation] = useState(false);
  const [modeContext, setModeContext] = useState();

  return (
    <Context.Provider
      value={{
        modeContext,
        setModeContext,
        isFocusAnnotation,
        setIsFocusAnnotation,
        uploadSuccessful,
        setUploadSuccessful,
        timer,
        setTimer,
        courseDescription,
        setCourseDescription,
        courseName,
        setCourseName,
        lessonName,
        setLessonName,
        lesson,
        setLesson,
        theme,
        setTheme,
        courseList,
        setCourseList,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useBaseURLs() {
  // const baseURL = `${window.location.protocol}//${window.location.hostname}:5000/api/`;
  const baseURL = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':5000' : '')}/api/`;

        // const baseURL = `https://appaula.prepara.com.br/api/`;

  return { baseURL };
}

export default Provider;

export function useTimer() {
  const context = useContext(Context);
  const { timer, setTimer } = context;
  return { timer, setTimer };
}

// export function useBaseURLs() {
//   const baseURL =
//     window.location.protocol +
//     "//" +
//     window.location.hostname +
//     ":" +
//     5000 +
//     "/api/";
//   return { baseURL };
// }

export function useCourse() {
  const context = useContext(Context);
  const {
    courseName,
    setCourseName,
    lessonName,
    setLessonName,
    lesson,
    setLesson,
    courseList,
    setCourseList,
    courseDescription,
    setCourseDescription,
  } = context;
  return {
    courseName,
    setCourseName,
    lessonName,
    setLessonName,
    lesson,
    setLesson,
    courseList,
    setCourseList,
    courseDescription,
    setCourseDescription,
  };
}
export function useTheme() {
  const context = useContext(Context);
  const { theme, setTheme } = context;
  return { theme, setTheme };
}
