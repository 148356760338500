import React from 'react';
import ReactDOM from 'react-dom';
import RouterPages from './Router';
import Provider from './state/Provider'

ReactDOM.render(
  <Provider>
    <RouterPages />
  </Provider>,
  document.getElementById('root')
);

