import React from "react";
import "./TitleEnsinaMais.css";
import title_image_01 from "../../Files/title_image_01.png";
import title_image_02 from "../../Files/title_image_02.png";

const TitleEnsinaMais = ({ props }) => {
  const courseName = props.courseName;
  const lesson = props.lesson;
  const timer = props.timer;
  const theme = props.theme;
  const contador = props.contador;
  const provao = props.provao;
  return (
    <div className="titleEnsinaMais">
      {courseName.toUpperCase()} -{" "}
      {provao ? "AVALIAÇÃO FINAL" : lesson.lessonName.toUpperCase()}
      {provao ? (
        <img src={title_image_01} className="title_image_01" />
      ) : (
        <img src={title_image_02} className="title_image_02" />
      )}
    </div>
  );
};

export default TitleEnsinaMais;
