import {
  faAngleLeft,
  faAngleRight,
  faCompressArrowsAlt,
  faExclamation,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowKeysReact from "arrow-keys-react";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Check as CheckIcon } from "tabler-icons-react";
import CapaTesteSeusConhecimentosEnsinaMais from "../../components/TesteConhecimentosEnsinaMais/CapaTesteSeusConhecimentosEnsinaMais";
import TC_perguntas from "../../components/TesteConhecimentos/TC_perguntas";
import Title from "../../components/Title/Title";
import Anotacoes from "../../components/_Anotacoes/Anotacoes";
import Enunciado from "../../components/_Enunciado/Enunciado";
import ProgressBar from "../../components/_ProgressBar/ProgressBar";
import loadingGif from "../../Files/loading.gif";
import {
  deleteGabarito,
  getCourses,
  getProvao,
  getRespostasGabarito,
  submitResposta,
} from "../../services";
import Context from "../../state/Context";
import { useBaseURLs, useCourse, useTheme } from "../../state/Provider";
import img_continuar from "./continuar.png";
import "./TesteConhecimentosEnsinaMais.css";
import fundo_ensinamais from "../../Files/fundo_ensinamais.png";
import footer_img from "../../Files/footer_mauricio.png";
import AtividadeSimples from "./TiposAtividadeEnsinaMais/AtividadeSimples";
import AtividadeDuplo from "./TiposAtividadeEnsinaMais/AtividadeDuplo";
import TitleEnsinaMais from "../../components/Title/TitleEnsinaMais";
import TelaFinalizacaoEnsinaMais from "./TelaFinalizacaoEnsinaMais/TelaFinalizacaoEnsinaMais";

Axios.defaults.headers.common["Authorization"] = `Bearer ${"pR889Q6+6CI9VuF"}`;

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

function TesteConhecimentosEnsinaMais(props) {
  const context = useContext(Context);
  const [userID, setUserID] = useState("");
  const [lessonID, setLessonID] = useState("");
  const [courseID, setCourseID] = useState("");
  var [isActive, setIsActive] = useState();
  var contador = null;
  const { courseName, setCourseName, lesson, setLesson } = useCourse();
  const { theme, setTheme } = useTheme();
  const [progress, setProgress] = useState(0);
  const [selectedAlternative, setSelectedAlternative] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [temAnotacao, setTemAnotacao] = useState(false);
  const [anotacaoVindaDoToken, setAnotacaoVindaDoToken] = useState("");
  const [indexAnotacao, setIndexAnotacao] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalRespondido, setModalRespondido] = useState(false);
  const [confirmouGeral, setConfirmouGeral] = useState(false);
  const [respostas, setRespostas] = useState([]);
  const [showingModal, setShowingModal] = useState(false);
  const categoria = 1;

  const [UrlArquivosAtividadePratica, setUrlArquivosAtividadePratica] =
    useState("");
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");

  let tela = false;

  const [telaInicial, setTelaInicial] = useState(0);

  if (
    localStorage.getItem("tela") !== undefined &&
    localStorage.getItem("tela") !== null
  ) {
    tela = parseInt(localStorage.getItem("tela"));
  }
  const [mode, setMode] = useState(0);

  let urlListarAnotacao = null;

  const [tokenInfo, setTokenInfo] = useState(null);

  const { provao } = props;
  useEffect(() => {
    const { token } = props;
    const { setarCor } = props;
    const { setFiltro } = props;
    setTokenInfo(token);

    localStorage.setItem("idAtividade", token.IdAtividade);

    setUrlArquivosAtividadePratica(token.UrlArquivosAtividadePratica);
    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);

    var root = document.querySelector(":root");
    root.style.setProperty("--backGroundImage", `url(${fundo_ensinamais})`);

    getCourses(token.IdMateria, token.IdAula, categoria)
      .then((response) => {
        console.log(response);
        // getRespostasGabarito(response.data[0].Lessons.idLesson).then(
        //   (response) => {
        //     console.log("RESPOSTAS: ", response.data);
        //     setRespostas(response.data);
        //   }
        // );
        if (token.IdRegiao === 1) {
          setarCor(response.data[0].cor1, response.data[0].bg1);
          setTheme(response.data[0].cor1);
          setFiltro(response.data[0].filtro1);
        }
        if (token.IdRegiao === 2) {
          setarCor(response.data[0].cor2, response.data[0].bg2);
          setTheme(response.data[0].cor2);
          setFiltro(response.data[0].filtro2);
        }
        if (token.IdRegiao === 3) {
          setarCor(response.data[0].cor3, response.data[0].bg3);
          setTheme(response.data[0].cor3);
          setFiltro(response.data[0].filtro3);
        }
        if (token.IdRegiao === 4) {
          setarCor(response.data[0].cor4, response.data[0].bg4);
          setTheme(response.data[0].cor4);
          setFiltro(response.data[0].filtro4);
        }

        setIsActive(response.data[0].Lessons.active);
        setLesson(response.data[0].Lessons);
        setCourseName(response.data[0].courseName);
        setLoaded(true);
        setUserID(token.IdAluno);
        setLessonID(response.data[0].Lessons.idLesson);
        setCourseID(response.data[0].idCourse);
        // console.log(response.data[0].Lessons);

        let new_lesson = response.data[0].Lessons;

        if (provao) {
          //remove all itens from localStorage where idAtividade === 6
          Object.keys(localStorage).forEach((key) => {
            if (key.includes("idAtividade")) {
              let el = localStorage.getItem(key);
              if (JSON.parse(el).idAtividade === "6") {
                localStorage.removeItem(key);
              }
            }
          });

          getProvao(token.IdMateria, token.IdAula, categoria).then(
            (response) => {
              console.log("PROVAO: ", response.data[0].Questions);
              //separete questions for each different idLesson

              let questions = response.data[0].Questions;
              let questionsByLesson = {};
              questions.forEach((question) => {
                if (questionsByLesson[question.idLesson] === undefined) {
                  questionsByLesson[question.idLesson] = [];
                }
                questionsByLesson[question.idLesson].push(question);
              });
              // console.log("questionsByLesson", questionsByLesson);

              //delete all questions from questionByLessons but leave a random X questions

              Object.keys(questionsByLesson).forEach((key) => {
                let questions = questionsByLesson[key];
                let randomQuestions = [];
                for (let i = 0; i < 1; i++) {
                  let randomIndex = Math.floor(
                    Math.random() * questions.length
                  );
                  randomQuestions.push(questions[randomIndex]);
                  questions.splice(randomIndex, 1);
                }
                questionsByLesson[key] = randomQuestions;
              });

              // console.log("questionsByLesson", questionsByLesson);

              //merge all questions into one array
              let newQuestions = [];
              Object.keys(questionsByLesson).forEach((key) => {
                newQuestions = newQuestions.concat(questionsByLesson[key]);
              });
              console.log("newQuestions", newQuestions);

              //replace questions in lesson
              new_lesson.Questoes = newQuestions;
              setLesson(new_lesson);
              console.log("new_lesson", new_lesson);

              //setRespostas with correctAlternative of each question
              let respostas = [];
              newQuestions.forEach((question) => {
                respostas.push(question.correctAlternative);
              });
              setRespostas(respostas);
              console.log("respostas", respostas);
            }
          );
        } else {
          let respostas = [];
          response.data[0].Lessons.Questoes.forEach((question) => {
            respostas.push(question.correctAlternative);
          });
          setRespostas(respostas);
        }
      })
      .then(() => {
        setTelaInicial(token.Tela);
        setModalRespondido(false);
      });

    Object.keys(localStorage).forEach((el, index) => {});
    urlListarAnotacao = token.UrlAnotacaoListar;

    let bodyListar = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoListar).CodigoContrato,
      Tipo: JSON.parse(token.JsonBodyAnotacaoListar).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoListar).Parceiro,
    });

    fetch(baseURL + "anotacao", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pR889Q6+6CI9VuF",
      },
      body: JSON.stringify({
        url: urlListarAnotacao,
        bodyAnotacao: bodyListar,
        tipo: "listar",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setIndexAnotacao(() => {
          if (data.Dados.length !== 0) {
            setTemAnotacao(true);
            return data.Dados[data.Dados.length - 1].idBlocoNota;
          } else {
            return null;
          }
        });
        let anotacao =
          (data.Dados.length !== 0 &&
            data.Dados[data.Dados.length - 1].Descricao) ||
          "";
        if (anotacao !== null) {
          localStorage.setItem(`anotacoes`, anotacao);
          setAnotacaoVindaDoToken(anotacao);
        }
      });
  }, []);

  window.onbeforeunload = function () {
    if (mode !== lesson.Questoes.length + 1) {
      saveTela();
      return 1;
    }
  };

  useEffect(() => {
    if (mode > 0) {
      props.turnOffCapa();
    } else {
      props.turnOnCapa();
    }
  }, [mode]);

  useEffect(() => {
    document.title = lesson.lessonName;
  }, [lesson.lessonName]);

  const previous = () => {
    setMode(parseInt(mode) - 1);
  };

  const next = () => {
    if (isActive) {
      if (mode < lesson.Questoes.length + 1) {
        setMode(parseInt(mode) + 1);
        localStorage.setItem(
          "tela",
          parseInt(localStorage.getItem("tela")) + 1
        );
      } else {
      }
    }
  };

  const submitFile = (file) => {
    var extensao = file.name.split(/\.(?=[^\.]+$)/),
      reader = new FileReader();
    reader.readAsDataURL(file);

    const post = function (base64) {
      Axios.post(UrlArquivosAtividadePratica, {
        CodigoContratoCursoMateria: userID,
        Nome: file.name + "." + extensao[1],
        CaminhoFTP: (+new Date()).toString(36),
        Extensao: extensao[1],
        ArquivoBase64: base64,
      }).then((response) => {
        // alert("Sucesso! Enviou os arquivos");
      });
    };

    reader.onloadend = function () {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      var b64 = reader.result.replace(/^data:.+;base64,/, "");
      //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
      // const blob = base64StringToBlob(b64, file.type);
      post(b64);
    };
  };

  const [showingSendButton, setShowingSendButton] = useState(true);

  const hideSendButton = () => {
    setShowingSendButton(false);
  };

  const confirmGeral = () => {
    let i = 0;

    let fitered_localStorage_keys = [];
    let fitered_localStorage_values = [];

    Object.values(localStorage).forEach((element, index) => {
      if (
        element.includes("resposta") &&
        !Object.keys(localStorage)[index].includes("anotacoes")
      ) {
        // console.log("ID LESSONS", lessonID);
        if (
          JSON.parse(element).lessonID === lessonID &&
          JSON.parse(element).userID === userID &&
          JSON.parse(element).idAtividade === tokenInfo.IdAtividade.toString()
        ) {
          fitered_localStorage_keys.push(Object.keys(localStorage)[index]);
          fitered_localStorage_values.push(element);
          i++;
        }
      }
    });
    // console.log("fitered_localStorage_keys ", fitered_localStorage_keys);
    // console.log("fitered_localStorage_values ", fitered_localStorage_values);
    console.log("QUANTIDADE: ", i);
    if (i === lesson.Questoes.length) {
      // hideSendButton();
      setIsLoading(true);

      let ordened_array_localStorage = [];

      fitered_localStorage_keys.forEach((value, index) => {
        let number = value.split("resposta ")[1].split(" ")[0];
        ordened_array_localStorage[number - 1] =
          fitered_localStorage_values[index];
      });

      console.log("ordened_array_localStorage", ordened_array_localStorage);

      saveTelaFinalizando(
        ordened_array_localStorage,
        fitered_localStorage_keys
      );
    } else {
      alert("Você deve responder todas as questões!");
    }
  };

  const saveTela = () => {
    let url = UrlApontamentos.replace("##Tela", "" + mode);
    if (mode >= 1) {
      Axios.post(url, {
        tela: mode,
      }).then((response) => {
        // alert("Enviou a tela que parou");
        // console.log("Enviou a tela que parou");
      });
    }
  };

  const saveTelaFinalizando = async (
    ordened_array_localStorage,
    fitered_localStorage_keys
  ) => {
    // let url = UrlApontamentos.replace("##Tela", parseInt(mode)).replace('/null', '/1')
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";

    // console.log("ordened_array_localStorage", ordened_array_localStorage);
    // console.log("fitered_localStorage_keys", fitered_localStorage_keys);

    let questoes = ordened_array_localStorage.map((element, index) => {
      return {
        idQuestao: JSON.parse(element).questionID || index + 1,
        resposta: JSON.parse(element).resposta + 1,
        categoria: JSON.parse(element).category,
        // alternativaCorreta: gabarito.data[0].respostas[index],
        alternativaCorreta: lesson.Questoes[index].correctAlternative,
        nomeQuestao: lesson.Questoes[index].questionName,
      };
    });

    const obj_gabarito = {
      curso: courseName,
      aula: lesson.lessonName,
      aluno: userID,
      questoes: questoes,
      acertos: questoes.filter((element) => {
        return element.resposta === element.alternativaCorreta;
      }).length,
      erros: questoes.filter((element) => {
        return element.resposta !== element.alternativaCorreta;
      }).length,
      numPerguntas: lesson.Questoes.length,
      progresso: "100",
    };
    // console.log("NEW: ", JSON.stringify(obj_gabarito));

    let url_leirson =
      "https://0064-189-19-104-171.ngrok.io/api/" + url.split("/api/")[1];

    // console.log("URL_NORMAL: ", url);
    // console.log("URL_LERISON: ", url_leirson);

    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj_gabarito),
    })
      .then((response) => {
        if (response.type == "opaque") {
          //remover itens do localStorage que foram salvos no banco, sem apagar o resto
          fitered_localStorage_keys.forEach((element) => {
            localStorage.removeItem(element);
          });
          setIsLoading(false);
          window.location.assign(UrlEncerramentoAula);
        }
      })
      .catch(function (err) {
        if (err) {
          console.log("ERRO: ", err);
        }
      });
  };

  useEffect(() => {
    // saveTela()
    context.setModeContext(mode);
  }, [mode]);

  let urlInserirAnotacao = null;
  let urlAlterarAnotacao = null;

  useEffect(() => {
    const { token } = props;
    urlInserirAnotacao = token.UrlAnotacaoInserir;
    urlAlterarAnotacao = token.UrlAnotacaoAlterar;

    let bodyInserirAnotacao = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoContrato,
      CodigoMateria: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoMateria,
      Tipo: JSON.parse(token.JsonBodyAnotacaoInserir).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoInserir).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoInserir).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
      IdAula: token.IdAula,
      IdAtividade: token.IdAtividade,
    });
    let bodyAlterarAnotacao = JSON.stringify({
      idBlocoNota: parseInt(indexAnotacao),
      Parceiro: JSON.parse(token.JsonBodyAnotacaoAlterar).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoAlterar).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
    });
    if (temAnotacao) {
      if (
        localStorage.getItem("anotacoes") !== null &&
        localStorage.getItem("anotacoes") !== anotacaoVindaDoToken
      ) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlAlterarAnotacao,
            bodyAnotacao: bodyAlterarAnotacao,
            tipo: "alterar",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log('index que foi alterado >>>  ', parseInt(indexAnotacao) )
            // console.log('response alterado>>>', data)
            setTemAnotacao(true);
          });
      }
    } else {
      // if (listaCarregada ){
      if (indexAnotacao === null) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlInserirAnotacao,
            bodyAnotacao: bodyInserirAnotacao,
            tipo: "inserir",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setTemAnotacao(true);
            // console.log('index da anotacao criada >>> ', data.Dados.idNota)
            setIndexAnotacao(data.Dados.idNota);
          });
      }
      // }
    }
  }, [context.isFocusAnnotation]);

  const resetLocalStorage = () => {
    setModalRespondido(true);
    localStorage.setItem("tela", 1);
    setMode(1);
    lesson.Questoes.forEach((el, index) => {
      var indexplus = index + 1;
      localStorage.removeItem(
        `${
          "resposta " +
          indexplus +
          " lessonID " +
          lesson.idLesson +
          " userID " +
          userID +
          " category " +
          categoria +
          "idAtividade " +
          tokenInfo.IdAtividade.toString()
        } `
      );
      // console.log(`${ "resposta " + indexplus + " lessonID " + lesson.idLesson + ' userID ' + userID + " category " + categoria } `)
    });
  };

  ArrowKeysReact.config({
    left: () => {
      // console.log('left key detected.');
      if (mode > 1) {
        previous();
      }
    },
    right: () => {
      // console.log('right key detected.');
      if (mode > 0 && mode < lesson.Questoes.length + 1) {
        next();
      }
    },
    up: () => {
      // console.log('up key detected.');
    },
    down: () => {
      // console.log('down key detected.');
    },
  });

  // <input type="text" value={answer} onKeyPress={handleAnswerChange} />

  const [modalToggle, setModalToggle] = useState(false);
  const enableModal = () => {
    if (modalToggle === true || telaInicial <= 1) {
      next();
    } else {
      setModalToggle(true);
      setShowingModal(true);
    }
  };

  const [showImageZoom, setShowImageZoom] = useState(false);
  const [imageFile, setImageFile] = useState();
  const toggleImageZoom = (estado, file) => {
    setImageFile(file);
    setShowImageZoom(estado);
  };

  const [mobile, setMobile] = useState("");
  useEffect(() => {
    setMobile(() => {
      if (mode >= 1) {
        return "main-mobile";
      }
    });
  }, [mode]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [iconFullScreen, setIconFullScreen] = useState(faExpandArrowsAlt);
  function openFullScreen() {
    setIsFullScreen(true);
    setIconFullScreen(faCompressArrowsAlt);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  }
  function closeFullScreen() {
    setIsFullScreen(false);
    setIconFullScreen(faExpandArrowsAlt);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  const modalStruct = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          <img style={{ width: "160px" }} src={img_continuar} alt="" />
          <p className="modal-texto" style={{ marginTop: "20px" }}>
            Deseja continuar de onde parou?
          </p>

          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              flex: "3.5",
            }}
          >
            <div
              onClick={() => {
                setMode(telaInicial);
                setModalRespondido(true);
                setShowingModal(false);
              }}
              className="modal-btn-sim"
            >
              Sim
            </div>
            <div
              onClick={() => {
                resetLocalStorage();
                setShowingModal(false);
              }}
              className="modal-btn-nao"
            >
              Não
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoading && (
        <img
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            height: "60px",
            zIndex: "9999",
          }}
          src={loadingGif}
        ></img>
      )}
      <button
        className="btn-fullscreen"
        onClick={() => (isFullScreen ? closeFullScreen() : openFullScreen())}
      >
        <FontAwesomeIcon
          icon={iconFullScreen}
          style={{ transform: "scale(1.35)" }}
        ></FontAwesomeIcon>
      </button>

      <div className="teste-conhecimentos-ensina-mais">
        <div hidden={false} id="modal">
          {loaded && <>{modalToggle && !modalRespondido && modalStruct()}</>}
        </div>

        {/* {mode !== 0 && (
          // <Title props={{ theme, courseName, lesson, lesson, contador }} />
          <TitleEnsinaMais props={{ theme, courseName, lesson, contador }} />
        )} */}

        {loaded ? (
          <>
            {isActive === 1 && lesson.Questoes !== null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  position: "relative",
                }}
              >
                <div id="conteudo">
                  {mode === 0 ? (
                    <>
                      <CapaTesteSeusConhecimentosEnsinaMais
                        props={{
                          provao,
                          theme,
                          lesson,
                          courseName,
                          contador,
                          progress,
                          mode,
                          enableModal,
                          showingModal,
                          horaDoLivro: false,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <section
                        style={{
                          height: "80vh",
                          width: "80vw",
                          // border: "1px solid red",
                          minHeight: "50vh",
                          // marginBottom: "20px",
                        }}
                        className="conteudo-ensina-mais"
                      >
                        {mode <= lesson.Questoes.length ? (
                          <>
                            <div style={{}}>
                              <TitleEnsinaMais
                                props={{
                                  provao,
                                  theme,
                                  courseName,
                                  lesson,
                                  contador,
                                }}
                              />
                            </div>
                            <AtividadeDuplo
                              provao={provao}
                              lesson={lesson}
                              mode={mode}
                              titleProps={{
                                theme,
                                courseName,
                                lesson,
                                contador,
                              }}
                              otherData={{
                                respostas,
                                toggleImageZoom,
                                confirmGeral,
                                userID,
                                submitFile,
                                theme,
                                mode,
                                next,
                                lesson,
                                setSelectedAlternative,
                                selectedAlternative,
                                previous,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TelaFinalizacaoEnsinaMais
                              showingSendButton={showingSendButton}
                              confirmGeral={confirmGeral}
                            />
                          </>
                        )}{" "}
                      </section>
                    </>
                  )}{" "}
                  <div>
                    {mode > 0 ? (
                      <>
                        <button
                          {...ArrowKeysReact.events}
                          tabIndex="1"
                          className="nav_buttons  nav_buttons_left"
                          style={{
                            border: "4px solid rgba(211,211,211,0.3)",
                            zIndex: "4",
                            fontWeight: "800",
                            fontSize: "1.8em",
                            borderRadius: "50%",
                            display: "flex",
                            position: "absolute",
                            justifyContent: "center",
                            background: { theme },
                            textAlign: "center",
                            alignItems: "center",
                          }}
                          onClick={() => previous()}
                        >
                          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                        </button>

                        {mode < lesson.Questoes.length + 1 ? (
                          <>
                            <button
                              {...ArrowKeysReact.events}
                              tabIndex="1"
                              className="nav_buttons nav_buttons_right"
                              style={{
                                border: "4px solid rgba(211,211,211,0.3)",
                                zIndex: "4",
                                fontWeight: "800",
                                fontSize: "1.8em",
                                borderRadius: "50%",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                background: { theme },
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => next()}
                            >
                              <FontAwesomeIcon
                                icon={faAngleRight}
                              ></FontAwesomeIcon>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <footer
                  style={{
                    // height: "4vh",
                    width: "65vw",
                    cursor: "default",
                    position: "fixed",
                    bottom: "0",
                  }}
                >
                  <img src={footer_img}></img>
                </footer>
              </div>
            ) : (
              <>
                <h1 style={{ color: "red", fontSize: "100px" }}>
                  Questões desativas pelo Admin
                </h1>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TesteConhecimentosEnsinaMais;
