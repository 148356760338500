import React from "react";
import Title2 from "../Title/Title2";
import ProgressBar from "../_ProgressBar/ProgressBar";
import "./CapaTesteSeusConhecimentos.css";

const Capa = ({ props }) => {
  const theme = props.theme;
  const lesson = props.lesson;
  const courseName = props.courseName;
  const timer = props.timer;
  const contador = props.contador;
  const progress = props.progress;
  const mode = props.mode;

  return (
    <div className="capa">
      {mode <= 0 && (
        <>
          <Title2 props={{ theme, courseName, lesson, timer, contador }}>
            {" "}
          </Title2>
          <div className="course-name-title-tc">Teste seus conhecimentos</div>
          <ProgressBar props={{ start: true, theme, mode, lesson, progress }} />
           
          <header className="capa-name">
            <h1 className="capa-name-2">
              Parabéns! Que bom ter você de volta. Agora que você voltou, vamos
              testar seus conhecimentos?
              <br></br>
              Responda as questões a seguir, marcando a alternativa correta em
              cada uma delas.
            </h1>
          </header>
        </>
      )}
    </div>
  );
};

export default Capa;

