import React from "react";
import ReactHtmlParser from "react-html-parser";
import Title2 from "../Title/Title2";
import Acesse from "../_Acesse/Acesse";
import ProgressBar from "../_ProgressBar/ProgressBar";
import "./Capa.css";

const Capa = ({ props }) => {
  const theme = props.theme;
  const lesson = props.lesson;
  const getFile = props.getFile;
  const courseName = props.courseName;
  const timer = props.timer;
  const contador = props.contador;
  const progress = props.progress;
  const mode = props.mode;

  return (
    <div>
      {mode <= 0 && (
        <>
          <div style={{ minWidth: "700px" }}>
            <Title2 props={{ theme, courseName, lesson, timer, contador }}>
              {" "}
            </Title2>
            <div className="course-name-title">Mão na massa</div>

            <ProgressBar
              props={{ start: true, theme, mode, lesson, progress }}
            />
          </div>

          <header className="capa-name">
            <h1 className="capa-name-2">
              {lesson.lessonDescription.split("##").length > 0 ? (
                <>
                  {ReactHtmlParser(lesson.lessonDescription)}
                  <br></br>
                  <br></br>
                  {lesson.acesse && (
                    <Acesse props={{ mode, theme, lesson, getFile }} />
                  )}
                </>
              ) : (
                <></>
              )}
            </h1>
          </header>
        </>
      )}
    </div>
  );
};

export default Capa;

