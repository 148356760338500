import React from "react";

export const Audio_Icon_Alternative = () => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_10_171)">
        <path
          d="M22.0769 9.33333C22.0769 9.33333 24.3077 10.381 24.3077 13C24.3077 15.619 22.0769 16.6667 22.0769 16.6667M24.3077 3.04762C28.7692 5.14286 31 8.28571 31 13C31 17.7143 28.7692 20.8571 24.3077 22.9524M2 8.28571V17.7143H7.57692L16.5 24V2L7.57692 8.28571H2Z"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10_171"
          x="0.75"
          y="0.75"
          width="32.5"
          height="25.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10_171"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10_171"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
