import React from "react";

export const Arrow_Icon = () => {
  return (
    <svg
      width="40"
      height="24"
      viewBox="-10 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.3827 12.6175 19.4563 12.5076 19.5064 12.3866C19.5566 12.2656 19.5824 12.1359 19.5824 12.005C19.5824 11.874 19.5566 11.7443 19.5064 11.6234C19.4563 11.5024 19.3827 11.3925 19.29 11.3L12.71 4.69998C12.6174 4.6074 12.5075 4.53396 12.3865 4.48385C12.2656 4.43375 12.1359 4.40796 12.005 4.40796C11.8741 4.40796 11.7444 4.43375 11.6235 4.48385C11.5025 4.53396 11.3926 4.6074 11.3 4.69998C11.2074 4.79256 11.134 4.90247 11.0839 5.02344C11.0338 5.1444 11.008 5.27405 11.008 5.40498C11.008 5.53591 11.0338 5.66556 11.0839 5.78652C11.134 5.90749 11.2074 6.0174 11.3 6.10998L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
        fill="white"
      />
    </svg>
  );
};
