import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./Objetiva.css";

const Objetiva = ({ props }) => {
  const lesson = props.lesson;
  const mode = props.mode;
  const setSelectedAlternative = props.setSelectedAlternative;
  const selectedAlternative = props.selectedAlternative;
  const alfabeto = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];
  const theme = props.theme;
  const cor_alternativa_correta = "#adf802";
  const cor_alternativa_errada = "tomato";
  let respostas;
  if (props.respostas) {
    respostas = props.respostas;
  }

  function qualquer() {
    var cor;
    var respostaCorreta;

    if (respostas[mode - 1] === selectedAlternative + 1) {
      cor = cor_alternativa_correta;
      // console.log("RESPONDEU CERTO");
      respostaCorreta = true;
    } else {
      // console.log("RESPONDEU ERRADO");
      cor = cor_alternativa_errada;
      respostaCorreta = false;
    }

    return { cor, respostaCorreta };
  }

  const item = `${
    "resposta " +
    mode +
    " lessonID " +
    lesson.idLesson +
    " userID " +
    props.userID +
    " category " +
    lesson.Questoes[mode - 1].category +
    " idAtividade " +
    localStorage.getItem("idAtividade")
  }`;

  useEffect(() => {
    setSelectedAlternative(-1);

    if (JSON.parse(localStorage.getItem(item)) !== null) {
      setSelectedAlternative(JSON.parse(localStorage.getItem(item)).resposta);
      // console.log('selectedAlternative', selectedAlternative)
      Responder();
    }
  }, [mode]);

  const [respondeuEsseMode, setRespondeuEsseMode] = useState([false]);
  const alternativas = (index, correto) => {
    // console.log("mode", mode)
    // console.log("index", index)
    // console.log("correto", correto)
    // console.log("respondeuEsseMode[mode]", respondeuEsseMode[mode])
    // console.log("selectedAlternative", selectedAlternative)

    if (!respondeuEsseMode[mode]) {
      return (
        <span className="circle">
          <p style={selectedAlternative === index ? {} : {}}>
            {" "}
            {alfabeto[index]}
          </p>
          {/* <FontAwesomeIcon icon={faCorrect} style={{ transform: "scale(1.35)" }}></FontAwesomeIcon> */}
        </span>
      );
    } else {
      return (
        <span
          className="circle"
          style={
            selectedAlternative === index
              ? { backgroundColor: correto.cor }
              : {}
          }
        >
          <p>{alfabeto[index]}</p>
          {selectedAlternative === index ? (
            correto.respostaCorreta ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: cor_alternativa_correta,
                  position: "absolute",
                  right: "100",
                  transform: "scale(3)",
                }}
              ></FontAwesomeIcon>
            ) : (
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  color: cor_alternativa_errada,
                  position: "absolute",
                  right: "100",
                  transform: "scale(3)",
                }}
              ></FontAwesomeIcon>
            )
          ) : (
            <></>
          )}
        </span>
      );
    }
  };

  const Responder = () => {
    // let newArr = [...respondeuEsseMode]; // copying the old datas array
    // newArr[mode] = true; // replace e.target.value with whatever you want to change it to
    // setRespondeuEsseMode(newArr);
    respondeuEsseMode[mode] = true;
    // console.log("respondeu a questao", mode + 1)
  };

  return (
    <div className="objetiva">
      {lesson.Questoes[mode - 1] !== null &&
        lesson.Questoes[mode - 1].Alternativas !== null &&
        lesson.Questoes[mode - 1].Alternativas !== undefined &&
        lesson.Questoes[mode - 1].Alternativas?.map((alternativa, index) => {
          return (
            <>
              {true ? (
                <div
                  onClick={() => {
                    Responder();
                  }}
                  style={
                    respondeuEsseMode[mode] && selectedAlternative === index
                      ? !qualquer().respostaCorreta
                        ? { background: "rgba(235, 64, 52,.1)" }
                        : { background: "rgba(173, 248, 2,.1)" }
                      : {}
                  }
                  className="alternative-box"
                  key={index}
                >
                  <label
                    htmlFor={index}
                    style={{
                      transition: "all 1s",
                      marginRight: "80px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <input
                      disabled={respondeuEsseMode[mode]}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedAlternative(() => {
                          localStorage.setItem(
                            `${
                              "resposta " +
                              mode +
                              " lessonID " +
                              lesson.idLesson +
                              " userID " +
                              props.userID +
                              " category " +
                              lesson.Questoes[mode - 1].category +
                              " idAtividade " +
                              localStorage.getItem("idAtividade")
                            }`,
                            JSON.stringify({
                              userID: props.userID,
                              resposta: index,
                              questionID: lesson.Questoes[mode - 1].idQuestion,
                              lessonID: lesson.idLesson,
                              category: lesson.Questoes[mode - 1].category,
                              idAtividade: localStorage.getItem("idAtividade"),
                            })
                          );
                          //  console.log("ESTE É O ID LESSON: ", lesson.idLesson)
                          return index;
                        });
                      }}
                      // defaultChecked={false}
                      checked={selectedAlternative === index ? true : false}
                      type="radio"
                      id={index}
                      value={"alternative" + index}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {alternativas(index, qualquer())}

                      {alternativa.Images ? (
                        <>
                          {
                            <div
                              key={index}
                              className="imageBox"
                              style={{
                                textAlign: "center",
                                marginLeft: "60px",
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <img
                                style={{
                                  boxShadow:
                                    "0px 0px 10px 1px rgba(28, 28, 28, 0.3)",
                                  borderRadius: "10px",
                                }}
                                src={`data: image/png;base64,${alternativa.Images[0].File}`}
                              />
                            </div>
                          }
                        </>
                      ) : (
                        <>
                          <p className="alternative-name">
                            {ReactHtmlParser(alternativa.alternativeName)}
                          </p>
                        </>
                      )}
                    </div>

                    <div className="box-resposta">{/* X V */}</div>
                  </label>
                </div>
              ) : (
                <div className="alternative-box" key={index}>
                  <label
                    htmlFor={index}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedAlternative(() => {
                          localStorage.setItem(
                            `${
                              "resposta " +
                              mode +
                              " lessonID " +
                              lesson.idLesson +
                              " userID " +
                              props.userID +
                              " category " +
                              lesson.Questoes[mode - 1].category +
                              " idAtividade " +
                              localStorage.getItem("idAtividade")
                            }`,
                            JSON.stringify({
                              userID: props.userID,
                              resposta: index,
                              questionID: lesson.Questoes[mode - 1].idQuestion,
                              lessonID: lesson.idLesson,
                              category: lesson.Questoes[mode - 1].category,
                              idAtividade: localStorage.getItem("idAtividade"),
                            })
                          );
                          //  console.log("ESTE É O ID LESSON: ", lesson.idLesson)
                          return index;
                        });
                      }}
                      // defaultChecked={false}
                      checked={selectedAlternative === index ? true : false}
                      type="radio"
                      id={index}
                      value={"alternative" + index}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="circle"
                        style={
                          selectedAlternative === index
                            ? {
                                border: "1px solid",
                                background: theme,
                                color: "white",
                              }
                            : {}
                        }
                      >
                        <p
                          style={
                            selectedAlternative === index
                              ? { color: "white" }
                              : {}
                          }
                        >
                          {" "}
                          {alfabeto[index]}
                        </p>
                      </span>

                      {alternativa.Images ? (
                        <>
                          {
                            <div
                              key={index}
                              className="imageBox"
                              style={{
                                textAlign: "center",
                                marginLeft: "60px",
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <img
                                style={{
                                  boxShadow:
                                    "0px 0px 10px 1px rgba(28, 28, 28, 0.3)",
                                  borderRadius: "10px",
                                }}
                                src={`data: image/png;base64,${alternativa.Images[0].File}`}
                              />
                            </div>
                          }
                        </>
                      ) : (
                        <>
                          <p className="alternative-name">
                            {ReactHtmlParser(alternativa.alternativeName)}
                          </p>
                        </>
                      )}
                    </div>
                  </label>
                </div>
              )}
            </>
          );
        })}
      <br></br>
      {/* Gabarito: Alternativa {lesson.Questoes[mode - 1].correctAlternative} */}
    </div>
  );
};

export default Objetiva;
