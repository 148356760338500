import "./App.css";
import React, { useState, useEffect } from "react";
import { authToken } from "./services";
import Render from "./Render";
import {ErrorModal} from "./errorModal";


function App(props) {
  const [token, setToken] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  var vez = 1;

  useEffect(() => {
    vez++;
    authToken({ token: props.match.params.token })
      .then((response) => {
        setToken(response.data.token);
        setLoaded(true);
        // console.log("TOKEN", response.data.tokenF);
      })
      .catch((error) => {

        if(error.message == "Network Error"){
          setErrorMessage("Houve um erro ao carregar a aula. \n\nPor favor, tente novamente.")
        }

        if (error.response) {
          if(error.response.data && error.response.data.token == "unlogged"){
            setErrorMessage("Identificamos que a aula foi recarregada durante o andamento.\n\nPor razões de segurança, feche a tela e abra novamente, clicando na atividade correspondente.");
          }else{
            setErrorMessage("Houve um erro ao carregar a aula. \n\nPor favor, tente novamente.")
          }
          console.log(error.response.status);
        }
        setError(true);
      });
  }, []);

  return (
    <>
      {loaded && (
        <div className="App">
          {/* <div className="botoesmenu"> */}
          <Render token={token}></Render>
          {/* </div> */}
        </div>
      )}
      {error && (
        <ErrorModal errorMessage={errorMessage}></ErrorModal>
      )}
    </>
  );
}

export default App;
