import React from "react";
const Close = () => {
  const close = () => {
    window.close();
  };
  return (
    <div>
      <button style={{ color: "black" }} onClick={close()}>
        Fechar pagina
      </button>
    </div>
  );
};

export default Close;

