import React from 'react'
import './Definicao.css'
const Definicao = ({ props }) => {

    const lesson = props.lesson
    const mode = props.mode

    return (
        <div className='balao_definicao'>
            <h1>Definicao: {lesson.Questoes[mode - 1].definicao}</h1>
        </div>
    )
}

export default Definicao
