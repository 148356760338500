import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import {authToken, getCourses} from "../../services";
import { useCourse, useTheme } from "../../state/Provider";
import ScormAPI from "./api";
import img_continuar from "./continuar.png";
import "./Genially.css";
import modal_educador from "../../Files/modal_educador.png";
import modal_educador_ss from "../../Files/modal_educador_ss.png";
import modal_educador_resposta_correta from "../../Files/modal_educador_resposta_correta.png";
import modal_educador_avaliar_screenshot from "../../Files/modal_educador_avaliar_screenshot.png";
import tente_novamente from "../../Files/tente_novamente.png";

var html2canvas = require("html2canvas");
function Genially(props) {
  const { setarCor } = props;
  const { setFiltro } = props;
  const { setCourseName, setLessonName } = useCourse();
  const [embedID, setEmbedID] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [isActive, setIsActive] = useState();
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");
  const [geniallyObject, setGeniallyObject] = useState([]);
  const [tela, setTela] = useState("______");
  const [modalToggle, setModalToggle] = useState(false);
  const [modalRespondido, setModalRespondido] = useState(false);
  const [idRegiao, setIdRegiao] = useState("");
  const [temScorm, setTemScorm] = useState([0, 0, 0]);
  const [telaLocalStorage, setTelaLocalStorage] = useState("");

  const [urlEnvioSs, setUrlEnvioSS] = useState("");
  const [userID, setUserID] = useState("");



  const [geniallyLink, setGeniallyLink] = useState("")

  let urlArquivosAtividadePratica = "";
  // let userID = "";


  const [geniallyToken, setToken] = useState("");
  const [tokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    window.API = new ScormAPI();
    // window.API.LMSInitialize("");
    const { token } = props;
    // console.log("token", token);

    urlArquivosAtividadePratica = token.UrlArquivosAtividadePratica;
    // userID = token.IdAluno;
    setUrlEnvioSS(token.UrlArquivosAtividadePratica);
    setUserID(token.IdAluno);

    // console.log("token.scorm: ", token.scorm)

    const jwt = token.scormToken

          if (token.IdAtividade == 1) { // se for do tipo normal
            let _url = window.location.protocol +
                "//" +
                window.location.hostname + (window.location.port ? ':5000' : '') +
                // ":5000" +
                "/api" +
                "/teorico" +
                "/curso" +
                token.IdMateria +
                "_aula" +
                token.IdAula +
                "_regiao" +
                token.IdRegiao + "/"
            // GetGeniallyContent(_url + "genially.html", _url)
            setGeniallyLink(_url + "genially.html" + "?token=" + jwt)

          } else { // se for do tipo aprofundase
            let _url = window.location.protocol +
                "//" +
                window.location.hostname + (window.location.port ? ':5000' : '') +
                // ":5000" +
                "/api" +
                "/teorico" +
                "/curso" +
                token.IdMateria +
                "_aula" +
                token.IdAula +
                "_regiao" +
                token.IdRegiao +
                "_aprofundase/"
            // GetGeniallyContent(_url + "genially.html", _url)
            setGeniallyLink(_url + "genially.html" + "?token=" + jwt)
          }
          setTokenLoaded(true);




    /// scorm

    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);

    getCourses(token.IdMateria, token.IdAula)
      .then((response) => {
        if (response.data[0].Lessons !== null) {
          if (token.IdRegiao === 1) {
            setarCor(response.data[0].cor1, response.data[0].bg1);
            setFiltro(response.data[0].filtro1);
          }
          if (token.IdRegiao === 2) {
            setarCor(response.data[0].cor2, response.data[0].bg2);
            setFiltro(response.data[0].filtro2);
          }
          if (token.IdRegiao === 3) {
            setarCor(response.data[0].cor3, response.data[0].bg3);
            setFiltro(response.data[0].filtro3);
          }
          if (token.IdRegiao === 4) {
            setarCor(response.data[0].cor4, response.data[0].bg4);
            setFiltro(response.data[0].filtro4);
          }

          setTela(token.Tela);
          var stringPadrao = "";
          localStorage.removeItem("cmi.core.lesson_location");

          if (token.IdRegiao === 1) {
            temScorm[0] = response.data[0].Lessons.scorm1;
            // console.log("temScorm[0]", temScorm[0]);

            setIdRegiao("1");

            // console.log(response.data[0]);
            if (response.data[0].Lessons.scorm1 === 1) {
              if (token.Tela === stringPadrao) {
                setModalRespondido(true);
              } else {
                setModalRespondido(false);
              }
            } else {
              setModalRespondido(true);
            }

            if (response.data[0].Lessons.embed1 !== null) {
              setEmbedID(response.data[0].Lessons.embed1);
            }
          }
          if (token.IdRegiao === 2) {
            /////////////////////////////////////////////////////////////////////////////////
            temScorm[1] = response.data[0].Lessons.scorm2;
            setIdRegiao("2");

            if (response.data[0].Lessons.scorm2 === 1) {
              if (token.Tela === stringPadrao) {
                setModalRespondido(true);
              } else {
                setModalRespondido(false);
              }
            } else {
              setModalRespondido(true);
            }

            if (response.data[0].Lessons.embed2 !== null) {
              setEmbedID(response.data[0].Lessons.embed2);
            }
          }
          if (token.IdRegiao === 3) {
            /////////////////////////////////////////////////////////////////////////////////
            temScorm[2] = response.data[0].Lessons.scorm3;
            setIdRegiao("3");

            if (response.data[0].Lessons.scorm3 === 1) {
              if (token.Tela === stringPadrao) {
                setModalRespondido(true);
              } else {
                setModalRespondido(false);
              }
            } else {
              setModalRespondido(true);
            }

            if (response.data[0].Lessons.embed3 !== null) {
              setEmbedID(response.data[0].Lessons.embed3);
            }
          }
          if (token.IdRegiao === 4) {
            /////////////////////////////////////////////////////////////////////////////////
            if (response.data[0].Lessons.embed4 !== null) {
              setEmbedID(response.data[0].Lessons.embed4);
            }
          }

          setLessonName(response.data[0].Lessons.lessonName);
          setCourseName(response.data[0].courseName);
          setLoaded(true);
          setIsActive(response.data[0].Lessons.active);

          setGeniallyObject({
            IdMateria: token.IdMateria,
            IdAula: token.IdAula,
            IdRegiao: token.IdRegiao,
          });
        } else {
          setEmbedID("61270a285dc05f0d17e6b74b"); ///// DEFAULT EMBED TEORICO
          setIsActive(true);
          setLoaded(true);
        }
      })
      .then(() => {
        let dev = false;
        let stringgg;
        if (true) {
          if (dev) {
            stringgg =
              "Question 2|62698be35d56e20011a09188|5acb07a12fc1330d9b65998b|5acb07a12fc1330d9b65998c";
          } else {
            stringgg = tela;
          }
        } else {
          stringgg = "null";
        }

        if (tela == "null") {
          loadTela(tela);
        } else {
          setModalToggle(true);
        }

        let x = (function (d) {
          var js,
            id = "genially-embed-js",
            ref = d.getElementsByTagName("script")[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement("script");
          js.id = id;
          js.async = true;
          js.src = "https://view.genial.ly/static/embed/embed.js";
          ref.parentNode.insertBefore(js, ref);
        })(document);
      });

    ///// SCORM <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,

    var vez = 1;

    var originalSetItem = localStorage.setItem;
    localStorage.setItem = function () {
      // document.createEvent('Event').initEvent('itemInserted', true, true);
      originalSetItem.apply(this, arguments);
      if (vez > 6) {
        if (
          telaLocalStorage !==
          window.localStorage.getItem("cmi.core.lesson_location")
        ) {
          setTelaLocalStorage(
            window.localStorage.getItem("cmi.core.lesson_location")
          );
        }
      }
      vez++;
    };
  }, []);

  const loadTela = (tela) => {
    window.localStorage.setItem("cmi.core.lesson_location", tela); //<<<<<<<<<<<<<<<<< mudar para string vinda do token
    setModalRespondido(true);
  };

  useEffect(() => {
    function delay(n) {
      return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
      });
    }

    async function myAsyncFunction() {
      await delay(3);
      // saveTela();
    }

    myAsyncFunction();
  }, [telaLocalStorage]);

  window.onbeforeunload = function () {
    saveTela();
    return 1;
  };

  const saveTela = () => {
    console.log("inciando salvamento tela")
    if (modalRespondido) {
      let url = UrlApontamentos.replace("##Tela", "" + "1");
      let string_tela = btoa(
        window.localStorage.getItem("cmi.core.lesson_location")
      );
      Axios.post(url, {
        tela: string_tela,
      })
        .then((response) => {
          // alert("Enviou a tela que parou");
/*          console.log(response)
          console.log("Enviou a tela que parou");*/
        })
        .catch((error) => {
          console.log("(post UrlApontamentos): " + error);
        });
    }
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [iconFullScreen, setIconFullScreen] = useState(faExpandArrowsAlt);
  function openFullScreen() {
    setIsFullScreen(true);
    setIconFullScreen(faCompressArrowsAlt);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  }
  function closeFullScreen() {
    setIsFullScreen(false);
    setIconFullScreen(faExpandArrowsAlt);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  const Finalizar = () => {
    let url = UrlApontamentos.replace("##Tela", 1) + "/1";
    // console.log("URL: ", url)

    let string_tela = btoa(
      window.localStorage.getItem("cmi.core.lesson_location")
    );

    Axios.post(url, {
      tela: string_tela,
    })
      .then((response) => {
        // alert("Enviou a tela que parou");
        if (response.status == 200) {
          // if (response.type == "opaque") {
          setTimeout(function () {
            localStorage.clear();
            window.location.assign(UrlEncerramentoAula);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("(post UrlApontamentos): " + error);
      });
  };

  const FinalizarOther1 = () => {
    let url = UrlApontamentos.replace("##Tela", 1) + "/1";
    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.type == "opaque") {
          localStorage.clear();
          // console.log("redirecionando")
        }
      })
      .catch(function (err) {
        if (err) {
          // console.log("ERRO: ", err)
        }
      });
  };

  const FinalizarOther2 = () => {
    window.location.assign(UrlEncerramentoAula);
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px #000",
    padding: "20px",
    borderRadius: "10px",
    width: "80%",
    height: "80%",
    overflow: "auto",
    zIndex: "9999",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#000",
    // backgroundColor: '#fff',
  };

  const modalStruct = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          <img style={{ width: "160px" }} src={img_continuar} alt="" />
          <p className="modal-texto" style={{ marginTop: "20px" }}>
            Deseja continuar de onde parou?
          </p>
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              flex: "3.5",
            }}
          >
            <div
              onClick={() => {
                loadTela(tela);
              }}
              className="modal-btn-sim"
            >
              Sim
            </div>
            <div
              onClick={() => {
                loadTela("null");
              }}
              className="modal-btn-nao"
            >
              Não
            </div>
          </span>
        </div>
      </div>
    );
  };

  const [showModalAviso, setShowModalAviso] = useState(false);
  const [showModalPosUploadSS, setShowModalPosUploadSS] = useState(false);
  const [msg_aviso_upload_ss, setMsgAvisoUploadSS] = useState("Salvando...");

  const ShowModalAviso = () => {
    setShowModalAviso(true);
  };

  const ShowModalProfessorResposta_localStorage = () => {
    let resposta = localStorage.getItem("resposta_correta");
    setShowModalProfessor(true);
    setCorrectAnswer(resposta);
  };

  const ShowModalProfessorResposta_A = () => {
    console.log("Executando Script Mostrar Modal");
    setShowModalProfessor(true);
    setCorrectAnswer("A");
  };

  const ShowModalProfessorResposta_B = () => {
    console.log("Executando Script Mostrar Modal");
    setShowModalProfessor(true);
    setCorrectAnswer("B");
  };
  const ShowModalProfessorResposta_C = () => {
    console.log("Executando Script Mostrar Modal");
    setShowModalProfessor(true);
    setCorrectAnswer("C");
  };
  const ShowModalProfessorResposta_D = () => {
    console.log("Executando Script Mostrar Modal");
    setShowModalProfessor(true);
    setCorrectAnswer("D");
  };

  const ShowModalProfessorResposta_Undefined = () => {
    console.log("Executando Script Mostrar Modal");
    setShowModalProfessor(true);
    setCorrectAnswer(undefined);
  };

  const SaveScreenshot = () => {
    setShowModalPosUploadSS(true);
    console.log("Executando Script Salvar Screenshot");
  };

  const [haveScreenshot, setHaveScreenshot] = useState(false);
  const [userCanvasScreenshot, setUserCanvasScreenshot] = useState("");

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    document.getComponentById("img_ss_professor").src = dataURL;

    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  //watch changes on localStorage on the "user_canvas_screenshot"
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "user_canvas_screenshot") {
        console.log("VALOR ----- user_canvas_screenshot: ", event.newValue);
        // UploadScreenshot();
        console.log("pronto para mostrar ss");
        // setHaveScreenshot(true);
        setHaveScreenshot(true);

        getBase64Image(localStorage.getItem("user_canvas_screenshot"));

        setUserCanvasScreenshot();
        // console.log("NEW VALUE: ", event.newValue);
        // setUserCanvasScreenshot(event.newValue);
        // TakeScreenshot();
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  function resizedataURL(datas, wantedWidth, wantedHeight) {
    // We create an image to receive the Data URI
    var img = document.createElement("img");

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      var dataURI = canvas.toDataURL();

      /////////////////////////////////////////
      // Use and treat your Data URI here !! //
      /////////////////////////////////////////
    };

    // We put the Data URI in the image's src attribute
    img.src = datas;
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //create a function to take screenshot of the canvas

  const TakeScreenshot = () => {
    const frame = document.getElementById("scorm_genially");
    html2canvas(document.body).then(function (canvas) {
      document.body.appendChild(canvas);
      console.log(canvas.toDataURL());
    });
  };

  //get baseb64 from localStorage (key "user_canvas_screent"), then transform it into a image file and upload to this route: urlArquivosAtividadePratica
  const UploadScreenshot = () => {
    // setMsgAvisoUploadSS("SALVANDO...");
    console.log("Executando Script Upload Screenshot");
    let base64 = localStorage
      .getItem("user_canvas_screenshot")
      .replace(/^data:.+;base64,/, "");
    let id_slide_screenshot = localStorage.getItem("id_slide_screenshot");

    const isBase64 = (str) => {
      if (str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    };
    // console.log("Is base64? ", isBase64(base64));
    // console.log(base64);

    const myInit = {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Authorization",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CodigoContratoCursoMateria: userID,
        Nome:
          "screenshot_" +
          id_slide_screenshot +
          "_codigo_" +
          (+new Date()).toString(36) +
          ".png",
        // "Nome": file.name + "." + extensao[1],s
        // CaminhoFTP: (+new Date()).toString(36) + ".png",
        CaminhoFTP:
          "screenshot_" +
          id_slide_screenshot +
          "_codigo_" +
          (+new Date()).toString(36) +
          ".png",
        Extensao: ".png",
        ArquivoBase64: base64,
      }),
    };
    console.log(" URL: ", urlEnvioSs);

    //wait for 0.seconds to upload the screenshot
    Axios({
      method: "post",
      data: myInit.body,
      url: urlEnvioSs,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
      .then((response) => {
        console.log("ENVIO: ", myInit.body);
        console.log("response envio screenshot: ", response);
        setMsgAvisoUploadSS("SALVO!");
        set_ss_enviada(true);
        // setShowModalProfessor(false);
      })
      .catch((error) => {
        console.log("error envio screenshot: ", error);
        setMsgAvisoUploadSS("Erro ao Salvar! Avise o educador");
      });
  };

  const [showModalProfessor, setShowModalProfessor] = useState(false);
  const [showModalProfessorSS, setShowModalProfessorSS] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState();

  const [showIsUploading, setShowIsUploading] = useState(false);

  const [ss_enviada, set_ss_enviada] = useState(false);

  //create a component that render a ModalProfessor that will display a input for a password, if the password is correct, show a word "correct" on the screen and a button to close

  // const ModalProfessorSS = () => {
  //   const [password, setPassword] = useState("");
  //   const [showAnswer, setShowAnswer] = useState(false);

  //   const handlePassword = (e) => {
  //     setPassword(e.target.value);
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     if (password === "123") {
  //       setShowAnswer(true);
  //     } else {
  //       alert("Senha Incorreta");
  //       // console.log("Senha Incorreta");
  //     }
  //   };
  //   return (
  //     //in the div style, dont allow to click on background
  //     <div
  //       style={{
  //         zIndex: "99999",
  //         position: "absolute",
  //         width: "100%",
  //         height: "100%",

  //         backdropFilter: "blur(12px)",
  //       }}
  //     >
  //       {/* <img
  //         style={{
  //           position: "absolute",
  //           top: "20%",
  //           left: "30%",
  //           width: "900px",
  //           pointerEvents: "none",
  //         }}
  //         src={modal_educador}
  //         alt="carregando tela..."
  //       /> */}
  //       <div
  //         style={{
  //           position: "absolute",
  //           //center the div
  //           //set the div size the image size
  //           top: "20%",
  //           left: "35%",

  //           width: "710px",
  //           height: "600px",

  //           backgroundImage: `url(${
  //             !showAnswer ? modal_educador : modal_educador_resposta_correta
  //           })`,
  //           //dont repeat the image
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       >
  //         <div
  //           style={{
  //             //center text on image center
  //             position: "absolute",
  //             top: "64%",
  //             left: "47.5%",
  //             transform: "translate(-50%, -50%)",
  //           }}
  //         >
  //           {/* <h2>Informe a senha do professor: 123</h2> */}
  //           {!showAnswer ? (
  //             <form onSubmit={handleSubmit}>
  //               <input
  //                 type="password"
  //                 value={password}
  //                 onChange={handlePassword}
  //                 style={{
  //                   backgroundColor: "#A6B5E1",
  //                   border: "none",
  //                   borderRadius: "10px",
  //                   height: "60px",
  //                   width: "200px",
  //                   paddingLeft: "50px",
  //                   paddingRight: "50px",
  //                   fontSize: "1.5rem",
  //                   marginTop: "20px",
  //                 }}
  //               />
  //               <button
  //                 type="submit"
  //                 style={{
  //                   backgroundColor: "#001554",
  //                   border: "none",
  //                   borderRadius: "10px",
  //                   height: "60px",
  //                   marginLeft: "10px",
  //                 }}
  //               >
  //                 OK
  //               </button>
  //             </form>
  //           ) : (
  //             <div
  //               style={{
  //                 marginBottom: "160px",

  //                 marginLeft: "-75px",
  //                 minWidth: "400px",
  //                 minHeight: "100px",
  //                 height: "100px",
  //                 textAlign: "center",
  //                 fontSize: "1.5rem",
  //                 fontWeight: "bold",
  //                 color: "#000",
  //               }}
  //             >
  //               <h2 className={"txt_modal_educador"}>
  //                 {correctAnswer !== undefined
  //                   ? correctAnswer.toUpperCase()
  //                   : "NÃO INFORMADO"}
  //               </h2>
  //               <button
  //                 style={{
  //                   marginTop: "96px",
  //                   backgroundColor: "#001554",
  //                   height: "60px",
  //                   width: "194px",
  //                   borderRadius: "6px",
  //                   border: "none",
  //                   fontSize: "2rem",
  //                   marginTop: "80px",
  //                   textAlign: "center",
  //                 }}
  //                 onClick={() => setShowModalProfessor(false)}
  //               >
  //                 FECHAR
  //               </button>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const ModalProfessor = () => {
    const [password, setPassword] = useState("");
    const [showAnswer, setShowAnswer] = useState(false);

    const handlePassword = (e) => {
      setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (password === "Emtm+9") {
        setShowAnswer(true);
      } else {
        alert("Senha Incorreta");
        // console.log("Senha Incorreta");
      }
    };

    return (
      //in the div style, dont allow to click on background
      <div
        style={{
          zIndex: "99999",
          position: "absolute",
          width: "100%",
          height: "100%",

          backdropFilter: "blur(12px)",
        }}
      >
        {/* <img
          style={{
            position: "absolute",
            top: "20%",
            left: "30%",
            width: "900px",
            pointerEvents: "none",
          }}
          src={modal_educador}
          alt="carregando tela..."
        /> */}
        <div
          style={{
            position: "absolute",
            //center the div
            //set the div size the image size
            top: "20%",
            left: "35%",

            width: "710px",
            height: "600px",

            backgroundImage: `url(${!showAnswer
              ? localStorage.getItem("mode_") === "resposta"
                ? modal_educador
                : modal_educador_ss
              : !haveScreenshot
                ? modal_educador_resposta_correta
                : ""
              // <img src={localStorage.getItem("user_canvas_screenshot")} />
              })`,
            //dont repeat the image
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              //center text on image center
              position: "absolute",
              top: "64%",
              left: "47.5%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {/* <h2>Informe a senha do professor: 123</h2> */}
            {!showAnswer ? (
              <form onSubmit={handleSubmit}>
                {haveScreenshot ? (
                  <div>
                    <input
                      type="password"
                      value={password}
                      onChange={handlePassword}
                      style={{
                        backgroundColor: "#A6B5E1",
                        // backgroundColor: "#ffff",
                        border: "none",
                        borderRadius: "10px",
                        height: "60px",
                        width: "200px",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        fontSize: "1.5rem",
                        marginTop: "20px",
                      }}
                    />
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#001554",
                        border: "none",
                        borderRadius: "10px",
                        height: "60px",
                        marginLeft: "10px",
                      }}
                    >
                      OK
                    </button>
                  </div>
                ) : (
                  localStorage.getItem("mode_") === "resposta" && (
                    <div>
                      <input
                        type="password"
                        value={password}
                        onChange={handlePassword}
                        style={{
                          backgroundColor: "#A6B5E1",
                          border: "none",
                          borderRadius: "10px",
                          height: "60px",
                          width: "200px",
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          fontSize: "1.5rem",
                          marginTop: "20px",
                        }}
                      />
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#001554",
                          border: "none",
                          borderRadius: "10px",
                          height: "60px",
                          marginLeft: "10px",
                        }}
                      >
                        OK
                      </button>
                    </div>
                  )
                )}
              </form>
            ) : localStorage.getItem("mode_") === "resposta" ? (
              <div
                style={{
                  marginBottom: "160px",
                  marginLeft: "-75px",
                  minWidth: "400px",
                  minHeight: "100px",
                  height: "100px",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                <h2 className={"txt_modal_educador"}>
                  {correctAnswer !== undefined
                    ? correctAnswer.toUpperCase()
                    : "NÃO INFORMADO"}
                </h2>
                <button
                  style={{
                    marginTop: "96px",
                    backgroundColor: "#001554",
                    height: "60px",
                    width: "194px",
                    borderRadius: "6px",
                    border: "none",
                    fontSize: "2rem",
                    marginTop: "80px",
                    textAlign: "center",
                  }}
                  onClick={() => setShowModalProfessor(false)}
                >
                  FECHAR
                </button>
              </div>
            ) : (
              <div>
                {showIsUploading ? <></> : <></>}
                {haveScreenshot ? (
                  <img
                    style={{
                      border: "2px solid #001554",
                      borderRadius: "6px",
                      position: "absolute",
                      top: "-140%",
                      width: "200px",
                      height: "100px",
                      scale: "4",
                      zIndex: "9999999",
                      marginLeft: "80px",
                    }}
                    alt="screenshot"
                    src={localStorage.getItem("user_canvas_screenshot")}
                  />
                ) : (
                  <div>carregando screenshot</div>
                )}

                {true ? (
                  <div
                    style={{
                      marginTop: "80px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#9B0100",
                        height: "60px",
                        width: "194px",
                        borderRadius: "6px",
                        border: "none",
                        fontSize: "2rem",
                        textAlign: "center",
                        paddingRight: "20px",
                      }}
                      onClick={() => {
                        setShowModalProfessor(false);
                        setHaveScreenshot(false);
                      }}
                    >
                      REFAZER
                    </button>
                    <button
                      style={{
                        backgroundColor: "#005925",
                        height: "60px",
                        width: "194px",
                        borderRadius: "6px",
                        border: "none",
                        fontSize: "2rem",
                        textAlign: "center",
                        marginLeft: "20px",
                      }}
                      onClick={() => {
                        UploadScreenshot();
                        setShowModalPosUploadSS(true);
                        setShowModalProfessor(false);
                        setHaveScreenshot(false);
                        // setShowIsUploading(true);
                      }}
                    >
                      ENVIAR
                    </button>
                  </div>
                ) : (
                  <div>asdasdasdsa </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ModalAviso = () => {
    return (
      //in the div style, dont allow to click on background
      <div
        style={{
          zIndex: "99999",
          position: "absolute",
          width: "100%",
          height: "100%",
          backdropFilter: "blur(12px)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20%",
            left: "35%",
            width: "760px",
            height: "600px",
            backgroundImage: `url(${tente_novamente})`,
            //dont repeat the image
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              //center text on image center
              position: "absolute",
              top: "61%",
              left: "39.1%",
              transform: "translate(-50%, -50%)",
              width: "400px",
            }}
          >
            <h1
              style={{
                fontSize: "1.8em",
                textAlign: "auto",
                textAlign: "center",
              }}
            >
              {/* Resposta errada, tente novamente */}
              {localStorage.getItem("txt_tentativa").toUpperCase()}
            </h1>
            <button
              style={{
                marginLeft: "100px",
                backgroundColor: "#001554",
                height: "60px",
                width: "194px",
                borderRadius: "6px",
                border: "none",
                fontSize: "2rem",
                marginTop: "30px",
                textAlign: "center",
              }}
              onClick={() => setShowModalAviso(false)}
            >
              FECHAR
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ModalConfirmacaoUploadSS = () => {
    return (
      //in the div style, dont allow to click on background

      <div
        style={{
          zIndex: "99999",
          position: "absolute",
          width: "100%",
          height: "100%",
          backdropFilter: "blur(12px)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20%",
            left: "35%",
            width: "760px",
            height: "600px",
            // backgroundImage: `url(${tente_novamente})`,
            //dont repeat the image
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              //center text on image center
              position: "absolute",
              top: "61%",
              left: "39.1%",
              transform: "translate(-50%, -50%)",
              width: "400px",
            }}
          >
            <h1
              style={{
                fontSize: "1.8em",
                textAlign: "auto",
                textAlign: "center",
              }}
            >
              {/* Resposta errada, tente novamente */}
              {/* {localStorage.getItem("txt_tentativa").toUpperCase()} */}
              {msg_aviso_upload_ss}
            </h1>
            {(msg_aviso_upload_ss == "SALVO!" ||
              msg_aviso_upload_ss == "Erro ao Salvar! Avise o educador") && (
                <button
                  style={{
                    marginLeft: "100px",
                    backgroundColor: "#001554",
                    height: "60px",
                    width: "194px",
                    borderRadius: "6px",
                    border: "none",
                    fontSize: "2rem",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setShowModalPosUploadSS(false);
                    setMsgAvisoUploadSS("SALVANDO...");
                  }}
                >
                  FECHAR
                </button>
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="screenshot_place">
      {showModalPosUploadSS && <ModalConfirmacaoUploadSS />}
      {showModalAviso && <ModalAviso />}
      {showModalProfessor && <ModalProfessor />}
      {/* {showModalProfessorSS && <ModalProfessorSS />} */}
      <div id="conteudoTeorico">
        <div
          onClick={() => ShowModalAviso()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Aviso"
        />
        <div
          onClick={() => ShowModalProfessorResposta_localStorage()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_localStorage"
        />
        <div
          onClick={() => ShowModalProfessorResposta_A()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_A"
        />
        <div
          onClick={() => ShowModalProfessorResposta_B()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_B"
        />
        <div
          onClick={() => ShowModalProfessorResposta_C()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_C"
        />
        <div
          onClick={() => ShowModalProfessorResposta_D()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_D"
        />
        <div
          onClick={() => ShowModalProfessorResposta_Undefined()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_telaProfessor_Resposta_Undefined"
        />
        <div
          onClick={() => SaveScreenshot()}
          style={{
            display: "none",
            color: "red",
            left: "50",
            top: "50",
            zIndex: "999",
            position: "absolute",
          }}
          id="fn_salvarScreenshot"
        />

        <div style={{}} hidden={false} id="modal">
          {loaded && (
            <>
              {/* {console.log("renderizando Novamente o Genially")} */}

              {modalToggle && !modalRespondido && <div>{modalStruct()}</div>}
            </>
          )}
        </div>

        {loaded && modalRespondido ? (
          <>
            <button
              className="btn-fullscreen-genially"
              onClick={() =>
                isFullScreen ? closeFullScreen() : openFullScreen()
              }
            >
              <FontAwesomeIcon
                icon={iconFullScreen}
                style={{ transform: "scale(4.8)" }}
              ></FontAwesomeIcon>
            </button>
            {/* {console.log("TEM SCORM?", temScorm[idRegiao - 1])} */}

            {isActive ? (
              <>
                <div className="Genially">{/* {title} */}</div>

                {/* {console.log("TEM SCORM?", temScorm)} */}

                {temScorm[idRegiao - 1] === 1 ? (
                  <>
                  {tokenLoaded && <div>
                      <iframe
                        id="scorm_genially"
                        frameBorder="0"
                        width="1200"
                        height="675"
                        style={{
                          zIndex: "1000",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: " 100%",
                        }}
                        // srcDoc={geniallyLink}
                        src={geniallyLink}
                        // sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-downloads"
                        // name="sandbox"
                        // src={
                        //   "/genially/genially.html"
                        // }
                        type="text/html"
                        allowscriptaccess="always"
                      ></iframe>
                      {/* {console.log("Este é um Genially SCORM")} */}
                    </div>
                  }
                    <div
                        onClick={() => saveTela()}
                        style={{
                          display: "none",
                          color: "red",
                          left: "50",
                          top: "50",
                          zIndex: "999",
                          position: "absolute",
                        }}
                        id="div_requisitar_salvamento_tela"
                    />
                    <div
                      onClick={() => Finalizar()}
                      style={{
                        display: "none",
                        color: "red",
                        left: "50",
                        top: "50",
                        zIndex: "999",
                        position: "absolute",
                      }}
                      id="telaFinalizacao"
                    >
                      {/* <button style={{ color: 'black' }} onClick={() => Finalizar()}>FINALIZAR</button> */}
                    </div>
                  </>
                ) : (
                  <>
                    {embedID !== null && embedID.includes("###") ? (
                      <>
                        <iframe
                          frameborder="0"
                          width="1200"
                          height="675"
                          style={{
                            zIndex: "1000",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: " 100%",
                          }}
                          src={window.location.origin + embedID.split("###")[1]}
                          type="text/html"
                          allowscriptaccess="always"
                        ></iframe>
                        {/* {console.log("Esta é uma Aula DELÍNEA")} */}

                        {/* {window.location.origin + embedID.split("###")[1]} */}
                        <div
                          onClick={() => Finalizar()}
                          style={{
                            display: "none",
                            color: "red",
                            left: "50",
                            top: "50",
                            zIndex: "999",
                            position: "absolute",
                          }}
                          id="telaFinalizacao"
                        >
                          {/* <button style={{ color: 'black' }} onClick={() => Finalizar()}>FINALIZAR</button> */}
                        </div>

                        <div
                          onClick={() => FinalizarOther1()}
                          style={{
                            display: "none",
                            color: "red",
                            left: "50",
                            top: "50",
                            zIndex: "99999",
                            position: "absolute",
                          }}
                          id="telaFinalizacaoOther1"
                        >
                          {/* <button style={{ color: 'black' }} onClick={() => Finalizar()}>FINALIZAR</button> */}
                        </div>

                        <div
                          onClick={() => FinalizarOther2()}
                          style={{
                            display: "none",
                            color: "red",
                            left: "50",
                            top: "50",
                            zIndex: "9999",
                            position: "absolute",
                          }}
                          id="telaFinalizacaoOther2"
                        >
                          {/* <button style={{ color: 'black' }} onClick={() => Finalizar()}>FINALIZAR</button> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="" className="Embed">
                          {embedID !== null && embedID !== "nulo" ? (
                            <>
                              <iframe
                                frameborder="0"
                                autoPlay
                                allow="autoplay"
                                width="1200"
                                height="675"
                                style={{
                                  zIndex: "1000",
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: " 100%",
                                }}
                                src={"https://view.genial.ly/" + embedID}
                                type="text/html"
                                allowscriptaccess="always"
                              ></iframe>
                              {/* < iframe id={"genially"} frameborder="0" autoPlay allow='autoplay' width="1200" height="675" style={{ zIndex: "1000", position: 'absolute', top: '0', left: '0', width: '10%', height: ' 10%', }} src={"https://view.genial.ly/6080b66730b6280d10531931/"} type="text/html" allowscriptaccess="always" ></iframe> */}
                              {/* {console.log("Este é um Genially LINKADO")} */}
                            </>
                          ) : (
                            <>Conteúdo não cadastrado.</>
                          )}
                        </div>

                        <div
                          onClick={() => Finalizar()}
                          style={{
                            display: "none",
                            color: "red",
                            left: "50",
                            top: "50",
                            zIndex: "999",
                            position: "absolute",
                          }}
                          id="telaFinalizacao"
                        >
                          {/* <button style={{ color: 'black' }} onClick={() => Finalizar()}>FINALIZAR</button> */}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <h1 style={{ color: "red", fontSize: "100px" }}>
                  Conteúdo desativado pelo Admin
                </h1>
              </>
            )}
          </>
        ) : (
          <>{/* Carregando... */}</>
        )}
      </div>
    </div>
  );
}

export default React.memo(Genially);
