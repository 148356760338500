import React, { useEffect } from "react";

const Background = ({ props }) => {
  const { img_background_geral } = props;
  useEffect(() => {}, []);
  console.log("img_background_geral", img_background_geral);

  return (
    <div
      style={{
        zIndex: "-15",
        overflow: "hidden",
        position: "absolute",
        height: "100vh",
        minHeight: "100vh",
        // top: -100,
        backgroundImage: `url(${img_background_geral})`,
        backgroundSize: `auto`,
        backgroundRepeat: ` no-repeat`,
        backgroundSize: `300px 100px`,
      }}
    >
      {/* <img src={img_background_geral} alt="background" /> */}
    </div>
  );
};

export default Background;
