import React from 'react'
import './Dica.css'
const Dica = ({ props }) => {

    const lesson = props.lesson
    const mode = props.mode

    return (
        <div className='balao_dica'>
            <h1>Dica: {lesson.Questoes[mode - 1].dica}</h1>
        </div>
    )
}

export default Dica
