import React, { useState, useEffect } from "react";
import './ProgressBar.css'


const ProgressBar = ({ props }) => {
    const quantidadePerguntas = props.lesson.Questoes.length

    const mode = parseInt(props.mode)

    let tipo = 0
    let category = 0
    if (!props.start) {

        if (props.mode !== props.lesson.Questoes.length + 1) {
            tipo = props.lesson.Questoes[mode - 1].questionType
            category = props.lesson.Questoes[mode - 1].category
        }
    }

    const [barWidth, setBarWidth] = useState('100%')
    const [barFill, setBarFill] = useState(0)

    const progress = props.progress
    const theme = props.theme

    // 

    useEffect(() => {
        if (mode === 0) {
            setBarWidth('34%')
            setBarFill('100%')
        } else {
            setBarWidth('100%')
            setBarFill(`${(mode / quantidadePerguntas * 100)}%`)
        }
    }, [mode])
    const containerStyles = {
        height: 5,
        width: barWidth,
        backgroundColor: "#C9C9C9",
        borderRadius: 10,
    }
    const fillerStyles = {
        height: '100%',
        width: barFill,
        backgroundColor: theme,
        borderRadius: 'inherit',
        textAlign: 'right'
    }
    const labelStyles = {
        padding: 5,
        fontSize: '0.6em',
        color: 'white',
        // fontWeight: 'bold',
        backgroundColor: theme,
        borderRadius: "200px"

    }

    return (
        <div >
            {props.mode !== props.lesson.Questoes.length + 1 ? (
                <>

                    {!props.start &&
                        <>
                            {category === 1 &&
                                <>
                                    <div className="container-tipos_questoes">

                                        {tipo === 1 && props.lesson.Questoes[mode - 1].questionType === 1 &&
                                            "Questão Objetiva"
                                        }

                                        {tipo === 1 && props.lesson.Questoes[mode - 1].questionType === 2 &&
                                            "Atividade"
                                        }
                                        {tipo === 2 &&
                                            "Preencha Lacuna"
                                        }
                                        {tipo === 3 &&
                                            "Associe Colunas"
                                        }
                                        {tipo === 4 &&
                                            "Arrastar Palavras"
                                        }
                                        {tipo === 5 &&
                                            "Arrastar Imagens"
                                        }
                                        {tipo === 6 &&
                                            "Verdadeiro ou Falso"
                                        }
                                        {tipo === 7 &&
                                            "Classificação Numérica de Palavras"
                                        }
                                        {tipo === 8 &&
                                            "Classificação Numérica de Imagens"
                                        }

                                        {tipo === 9 &&
                                            "Questão Dissertativa"
                                        }
                                        {tipo === 10 &&
                                            "Upload de Arquivo"
                                        }
                                        {tipo === 11 &&
                                            "Download de Arquivo"
                                        }
                                        {tipo === 12 &&
                                            "Imagem no enunciado"
                                        }
                                        {tipo === 13 &&
                                            "Marque as Alternativas"
                                        }

                                    </div>
                                </>
                            }

                        </>}

                    {category === 2 &&
                        <>
                            <div className="container-tipos_questoes">

                                {tipo === 1 &&
                                    "Atividade"
                                }

                                {tipo === 2 &&
                                    "Preencha Lacuna"
                                }
                                {tipo === 3 &&
                                    "Associe Colunas"
                                }
                                {tipo === 4 &&
                                    "Arrastar Palavras"
                                }
                                {tipo === 5 &&
                                    "Arrastar Imagens"
                                }
                                {tipo === 6 &&
                                    "Verdadeiro ou Falso"
                                }
                                {tipo === 7 &&
                                    "Classificação Numérica de Palavras"
                                }
                                {tipo === 8 &&
                                    "Classificação Numérica de Imagens"
                                }

                                {tipo === 9 &&
                                    "Questão Dissertativa"
                                }
                                {tipo === 10 &&
                                    "Upload de Arquivo"
                                }
                                {tipo === 11 &&
                                    "Download de Arquivo"
                                }
                                {tipo === 12 &&
                                    "Imagem no enunciado"
                                }
                                {tipo === 13 &&
                                    "Marque as Alternativas"
                                }

                            </div>
                        </>
                    }

                </>) : (
                <>

                    <div className="container-tipos_questoes">
                        {category === 1 ? (
                            <>
                                Enviar Respostas
                            </>
                        ) : (
                            <>
                                Finalizar
                            </>
                        )}

                    </div>


                </>
            )
            }



            <div style={containerStyles}>
                <div style={fillerStyles}>

                    {/* <span style={labelStyles}>{`${mode}/${quantidadePerguntas}`}</span>
                    <span style={labelStyles}>{`${progress}/${quantidadePerguntas}`}</span> */}
                </div>
            </div>

        </div >
    );
};

export default ProgressBar;