import React from "react";
import "./Title2.css";

const Title = ({ props }) => {
  const courseName = props.courseName;
  const lesson = props.lesson;
  const timer = props.timer;
  const theme = props.theme;
  const contador = props.contador;
  const provao = props.provao;
  return (
    <header className="title2">
      <div>
        {courseName} - {provao ? "PROVAO" : lesson.lessonName}
      </div>
    </header>
  );
};

export default Title;
