import React, { useState, useEffect, useRef, useContext } from 'react'
import './Anotacoes.css'

import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
/////
import { data } from './sheet.js';


import SunEditor from 'suneditor-react';
import './suneditor.min.css'; // Import Sun Editor's CSS File

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import Context from '../../state/Context'


const Anotacoes = ({ props }) => {
    const context = useContext(Context)
    let qtdInicialTexto = 20000
    const [qtdPalavras, setQtdPalavras] = useState(qtdInicialTexto)
    const [texto, setTexto] = useState('')
    const item = `anotacoes`
    useEffect(() => {
        if (localStorage.getItem(item) !== null && localStorage.getItem(item) !== '') {
            setTexto(localStorage.getItem(item))
        } else {

        }
    }, [])
    useEffect(() => {
        // localStorage.setItem(`anotacoes`, texto)
    }, [texto])
    useEffect(() => {
        // console.log(localStorage.getItem(item))



        setQtdPalavras(qtdInicialTexto)






    }, [context.modeContext])

    function handleChange(e) {
        console.log(e); //Get Content Inside Editor
    }


    return (
        <div className='anotacoes'>
            <h2>Anotações</h2>
            <br></br>

            <textarea placeholder="Use este espaço para fazer suas anotações.
Estas anotações ficarão salvas no seu navegador enquanto estiver no seu ambiente online de estudos. 
Bons estudos!
            " value={texto} onFocus={() => { context.setIsFocusAnnotation(false) }} onBlur={() => { context.setIsFocusAnnotation(true) }} onChange={(e) => {
                    localStorage.setItem(`anotacoes`, e.target.value);
                    setQtdPalavras(qtdInicialTexto - e.target.value.length)

                    if (qtdPalavras > 0 && e.target.value.length < qtdInicialTexto) {
                        setTexto(e.target.value)
                    }
                    if (qtdInicialTexto <= e.target.value.length) {
                        setQtdPalavras(0)
                    }

                }}
                id="index" >
            </textarea>

            {/* <textarea value={texto} autoFocus onChange={(e) => {
                setQtdPalavras(qtdInicialTexto - e.target.value.length)
                if (qtdPalavras > 0 && e.target.value.length < qtdInicialTexto) {
                    setTexto(e.target.value)

                }
                if (qtdInicialTexto <= e.target.value.length) {
                    setQtdPalavras(0)
                }

            }}
                id="index" >
            </textarea> */}
        </div >
    )
}

export default Anotacoes
