/* eslint-disable react/jsx-pascal-case */
// import './MaoMassa.css';
import "./PenseResponda.css";
import React, { useState, useEffect, useContext } from "react";
import { useTimer, useCourse, useTheme } from "../../state/Provider";
import { getCourses, submitResposta, validate } from "../../services";

import Title from "../../components/Title/Title";
import Background from "../../components/Background/Background";
import logo from "./mm_img.png";
import { useBaseURLs } from "../../state/Provider";

import Acesse from "../../components/_Acesse/Acesse";

import CapaPR from "../../components/PenseResponda/CapaPR";
import fundo from "../../Files/bg_geral.png";

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

function PenseResponda(props) {
  const [showEmbed, setShowEmbed] = useState(false);
  var token = null;
  const [userID, setUserID] = useState("");
  const [lessonID, setLessonID] = useState("");
  const [progress, setProgress] = useState(0);
  var [isActive, setIsActive] = useState();
  const [textResponse, setTextResponse] = useState("");

  const categoria = 2;

  var contador = null;
  const { courseName, setCourseName, lesson, setLesson } = useCourse();

  const { theme, setTheme } = useTheme();
  const [testList, setTestList] = useState([]);
  const [mode, setMode] = useState(0);
  const { timer, setTimer } = useTimer(3);
  const [loaded, setLoaded] = useState(false);
  const [backgroundEmbedID, setBackgroundEmbedID] = useState("");
  const [capaEmbedID, setCapaEmbedID] = useState("");
  var tempo = null;

  const [confirmouGeral, setConfirmouGeral] = useState(false);

  const [modalTela, setModalTela] = useState("flex");
  const [modalRespondido, setModalRespondido] = useState(false);

  const [UrlArquivosAtividadePratica, setUrlArquivosAtividadePratica] =
    useState("");
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");
  let tela = false;

  const [telaInicial, setTelaInicial] = useState(0);

  if (
    localStorage.getItem("tela") !== undefined &&
    localStorage.getItem("tela") !== null
  ) {
    tela = parseInt(localStorage.getItem("tela"));
  }

  // var root = document.querySelector(":root");
  // // var rootStyles = getComputedStyle(root);

  var root = document.querySelector(":root");
  root.style.setProperty("--backGroundImage", `url(${fundo})`);

  useEffect(() => {
    const { token } = props;
    const { themeGeral } = props;
    const { setarCor } = props;
    const { setFiltro } = props;
    // console.log('token:', token)
    setTheme(themeGeral);
    // console.log('token:', token)
    setUrlArquivosAtividadePratica(token.UrlArquivosAtividadePratica);
    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);

    // setTheme(token.theme)

    getCourses(token.IdMateria, token.IdAula, categoria)
      .then((response) => {
        setarCor(response.data[0].cor1, response.data[0].bg1);
        setTheme(response.data[0].cor1);
        // var deg = rootStyles.getPropertyValue('--themeColor');
        // setsetCapaEmbedID(response.data[0].Lessons.background)

        if (token.IdRegiao === 1) {
          setarCor(response.data[0].cor1, response.data[0].bg1);
          setTheme(response.data[0].cor1);
          setFiltro(response.data[0].filtro1);
        }
        if (token.IdRegiao === 2) {
          setarCor(response.data[0].cor2, response.data[0].bg2);
          setTheme(response.data[0].cor2);
          setFiltro(response.data[0].filtro2);
        }
        if (token.IdRegiao === 3) {
          setarCor(response.data[0].cor3, response.data[0].bg3);
          setTheme(response.data[0].cor3);
          setFiltro(response.data[0].filtro3);
        }
        if (token.IdRegiao === 4) {
          setarCor(response.data[0].cor4, response.data[0].bg4);
          setTheme(response.data[0].cor4);
          setFiltro(response.data[0].filtro4);
        }

        // if (token.IdRegiao === 1) {
        //     var corTheme1 = "#FD8942"
        //     root.style.setProperty('--themeColor', corTheme1);
        //     setTheme(corTheme1)

        //     setBackgroundEmbedID('611737fb786c590d9881cfa6')
        //     setCapaEmbedID("61282606c401400de24b9843")
        // }
        // if (token.IdRegiao === 2) {
        //     var corTheme2 = "#F6B33E"
        //     root.style.setProperty('--themeColor', corTheme2);
        //     setTheme(corTheme2)

        //     setBackgroundEmbedID('611737b6786c590d9881cfa2')
        //     setCapaEmbedID("61282606c401400de24b9843")
        // }
        // if (token.IdRegiao === 3) {
        //     var corTheme3 = "#008037"
        //     root.style.setProperty('--themeColor', corTheme3);
        //     setTheme(corTheme3)

        //     setBackgroundEmbedID("611737a3786c590d9881cfa0")
        //     setCapaEmbedID("61282606c401400de24b9843")
        // }
        // if (token.IdRegiao === 4) {
        //     var corTheme4 = "#0A2549"
        //     root.style.setProperty('--themeColor', corTheme4);
        //     setTheme(corTheme4)

        //     setBackgroundEmbedID('61173738786c590d9881cf98')
        //     setCapaEmbedID("61282606c401400de24b9843")
        // }

        setTestList(response.data[0]);
        setIsActive(response.data[0].Lessons.active);
        setLesson(response.data[0].Lessons);
        tempo = parseFloat(response.data[0].Lessons.timer);
        setCourseName(response.data[0].courseName);
        setLoaded(true);
        setUserID(token.IdAluno);
        setLessonID(token.IdAula);
      })
      .then(() => {});
  }, []);

  useEffect(() => {
    document.title = lesson.lessonName;
    // console.log(lesson)
  }, [lesson.lessonName]);

  // const Finalizar = () => {
  //     var link = document.createElement("a");
  //     link.href = 'https://sistemas.moveedu.com.br/hub/EncerramentoAula';
  //     console.log("chame o click")
  //     link.click();

  // }

  // const Finalizar = () => {
  //     let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + '/1'
  //     fetch(url, {
  //         method: 'POST',
  //         mode: 'no-cors',
  //     }).then((response) => {
  //         if (response.type == "opaque") {
  //             localStorage.clear()
  //             console.log("redirecionando")
  //             window.location.assign(UrlEncerramentoAula)
  //         }
  //     }).catch(function (err) {
  //         if (err) {
  //             // console.log("ERRO: ", err)
  //         }
  //     });
  // }
  const Finalizar = () => {
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";
    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.type == "opaque") {
          localStorage.clear();
          console.log("redirecionando");
          window.location.assign(UrlEncerramentoAula);
        }
      })
      .catch(function (err) {
        if (err) {
          // console.log("ERRO: ", err)
        }
      });
  };

  return (
    <>
      {/* {loaded && <Background props={{}} />} */}

      <div className="teste-conhecimentos">
        {mode !== 0 && (
          <Title
            props={{ theme, courseName, lesson, lesson, timer, contador }}
          />
        )}

        <>
          {loaded ? (
            <>
              {/* <button onClick={() => getGabarito(userID, lesson.idLesson)}>GABARITO</button> */}
              <main id="conteudo" style={{ display: "" }}>
                <section className="section-left">
                  <div className="section-left-1 items">
                    <div className="tela-inicial-left">
                      <CapaPR
                        props={{
                          theme,
                          lesson,
                          courseName,
                          timer,
                          contador,
                          progress,
                          mode,
                        }}
                      />
                    </div>
                  </div>

                  <div className="section-left-2 items">
                    {/* {mode === 0 && <button className="btn-comecar-left" >IR PARA A APOSTILA</button>} */}

                    <div>
                      {/* <button className="btn-comecar-left" onClick={() => confirmGeral()}>ENVIAR RESPOSTAS</button> */}
                      <button
                        className="btn-comecar-left"
                        onClick={() => Finalizar()}
                      >
                        FECHAR
                      </button>
                    </div>
                  </div>

                  <div className="all-itens"></div>
                </section>

                <section className="section-right">
                  {mode === 0 && (
                    <div className="imgbg-mao-na-massa">
                      {/* <img src={logo} alt="Logo" /> */}
                      {/* <div style={{ pointerEvents: 'none', zIndex: '-15', overflow: 'hidden', position: 'absolute', width: "80%", height: "80%", minWidth: "80vh", minHeight: "70vh" }}>
                                                    <iframe allowScripts="all" style={{ zIndex: '-5', overflow: 'hidden', width: "100vw", height: "100%" }} frameBorder="0" src={`https://view.genial.ly/${capaEmbedID}`} type="text/html" >
                                                    </iframe>
                                                </div>
 */}
                    </div>
                  )}

                  {mode === 0 && loaded && (
                    <div className="tela-inicial-right tela-inicial-right-mao-na-massa">
                      <CapaPR
                        props={{
                          theme,
                          lesson,
                          courseName,
                          timer,
                          contador,
                          progress,
                          mode,
                        }}
                      />

                      <div style={{ height: "100%" }}></div>

                      <div>
                        {/* <button className="btn-comecar-left" onClick={() => confirmGeral()}>ENVIAR RESPOSTAS</button> */}
                        <button
                          className="btn-comecar-left"
                          onClick={() => Finalizar()}
                        >
                          FECHAR
                        </button>
                      </div>
                    </div>
                  )}
                </section>
              </main>
            </>
          ) : (
            <>Carregando...</>
          )}
        </>
      </div>
    </>
  );
}

export default PenseResponda;
