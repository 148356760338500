import React from "react";
import ReactHtmlParser from "react-html-parser";
import Title2 from "../Title/Title2";
import ProgressBarPR from "../_ProgressBar/ProgressBarPR";
import "./CapaPR.css";

const CapaPR = ({ props }) => {
  const theme = props.theme;
  const lesson = props.lesson;
  const courseName = props.courseName;
  const timer = props.timer;
  const contador = props.contador;
  const progress = props.progress;
  const mode = props.mode;

  return (
    <div>
      {mode <= 0 && (
        <>
          <div style={{ width: "100vw", minWidth: "400px" }}>
            <Title2 props={{ theme, courseName, lesson, timer, contador }}>
              {" "}
            </Title2>
            <div
              style={{ whiteSpace: `pre-line` }}
              className="course-name-title-tc"
            >
              {`Pense e
                             responda`}
            </div>
            <ProgressBarPR props={{ theme }}></ProgressBarPR>
          </div>

          <header className="capa-name">
            <h1 className="capa-name-2">
              {lesson.penseresponda ? (
                <>{ReactHtmlParser(lesson.penseresponda)}</>
              ) : (
                <>
                  Parabéns! Você chegou até aqui. Comemore todas as suas
                  conquistas. Assim, a sua caminhada vai ficar mais fácil e
                  prazerosa. E por falar nisto, abra a sua apostila no final da
                  aula {lesson.numAula} e responda as 5 questões que lhe são
                  solicitadas por lá.
                </>
              )}
            </h1>
          </header>
        </>
      )}
    </div>
  );
};

export default CapaPR;

