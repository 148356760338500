import React from "react";
import ReactHtmlParser from "react-html-parser";
import ObjetivaEnsinaMais from "../../../components/TesteConhecimentos/Tipos/ObjetivaEnsinaMais";
import "./AtividadeSimples.css";
import TitleEnsinaMais from "../../../components/Title/TitleEnsinaMais";
import useSound from "use-sound";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { Audio_Icon } from "../../../svg_files/audio_icon";

const AtividadeSimples = (props) => {
  const { lesson } = props;
  const { mode } = props;
  const { otherData } = props;
  const { titleProps } = props;
  const { questionSound } = props;
  console.log("SOM PERGUNTA: ", questionSound);
  const [playSound] = useSound(questionSound);
  const { questionTitle } = props;
  const { hasQuestions } = props;
  const { introContent } = props;
  const { singular } = props;

  return (
    <div
      className="container_atividade_simplesEnsinaMais"
      style={{ width: singular ? "70%" : "48%" }}
    >
      <div className="container_pergunta" style={{}}>
        <div className="pergunta" style={{}}>
          {ReactHtmlParser(
            questionTitle !== null ? questionTitle.toUpperCase() : ""
          )}
        </div>
        {/* {console.log("VEIO ESTE SOM: ", questionSound)} */}
        {questionSound && (
          <div className="caixa_som_pergunta" onClick={() => playSound()}>
            <Audio_Icon />
          </div>
        )}
      </div>

      <div
        style={{
          paddingTop: "10vh",
          width: "100%",
        }}
      >
        {hasQuestions ? (
          <div style={{}} className="container_perguntas">
            {" "}
            <ObjetivaEnsinaMais props={otherData} />
          </div>
        ) : (
          <div style={{}} className="container_embed">
            {" "}
            {ReactHtmlParser(introContent)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AtividadeSimples;
