import {
  faAngleLeft,
  faAngleRight,
  faCompressArrowsAlt,
  faExclamation,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowKeysReact from "arrow-keys-react";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Check as CheckIcon } from "tabler-icons-react";
import CapaTesteSeusConhecimentos from "../../components/TesteConhecimentos/CapaTesteSeusConhecimentos";
import TC_perguntas from "../../components/TesteConhecimentos/TC_perguntas";
import Title from "../../components/Title/Title";
import Anotacoes from "../../components/_Anotacoes/Anotacoes";
import ProgressBar from "../../components/_ProgressBar/ProgressBar";
import loadingGif from "../../Files/loading.gif";
import {
  deleteGabarito,
  getCourses,
  getRespostasGabarito,
  submitResposta,
} from "../../services";
import Context from "../../state/Context";
import { useBaseURLs, useCourse, useTheme } from "../../state/Provider";
import img_continuar from "./continuar.png";
import "./TesteConhecimentos.css";
import Background from "../../components/Background/Background";

import fundo from "../../Files/bg_geral.png";

Axios.defaults.headers.common["Authorization"] = `Bearer ${"pR889Q6+6CI9VuF"}`;

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

function TesteConhecimentos(props) {
  const context = useContext(Context);
  const [userID, setUserID] = useState("");
  const [lessonID, setLessonID] = useState("");
  const [courseID, setCourseID] = useState("");
  var [isActive, setIsActive] = useState();
  var contador = null;
  const { courseName, setCourseName, lesson, setLesson } = useCourse();
  const { theme, setTheme } = useTheme();
  const [progress, setProgress] = useState(0);
  const [selectedAlternative, setSelectedAlternative] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [temAnotacao, setTemAnotacao] = useState(false);
  const [anotacaoVindaDoToken, setAnotacaoVindaDoToken] = useState("");
  const [indexAnotacao, setIndexAnotacao] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modalRespondido, setModalRespondido] = useState(false);
  const [confirmouGeral, setConfirmouGeral] = useState(false);
  const [respostas, setRespostas] = useState([]);
  const [showingModal, setShowingModal] = useState(false);
  const categoria = 1;

  const [UrlArquivosAtividadePratica, setUrlArquivosAtividadePratica] =
    useState("");
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");

  let tela = false;

  const [telaInicial, setTelaInicial] = useState(0);

  if (
    localStorage.getItem("tela") !== undefined &&
    localStorage.getItem("tela") !== null
  ) {
    tela = parseInt(localStorage.getItem("tela"));
  }
  const [mode, setMode] = useState(0);

  let urlListarAnotacao = null;

  var root = document.querySelector(":root");
  root.style.setProperty("--backGroundImage", `url(${fundo})`);
  // root.style.setProperty("--backGroundImage", `url(${fundo})`);

  const [tokenInfo, setTokenInfo] = useState(null);

  useEffect(() => {
    const { token } = props;
    const { setarCor } = props;
    const { setFiltro } = props;

    setTokenInfo(token);
    localStorage.setItem("idAtividade", token.IdAtividade);

    setUrlArquivosAtividadePratica(token.UrlArquivosAtividadePratica);
    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);

    getCourses(token.IdMateria, token.IdAula, categoria)
      .then((response) => {
        // getRespostasGabarito(response.data[0].Lessons.idLesson).then(
        //   (response) => {
        //     // console.log(response.data);
        //     setRespostas(response.data);
        //   }
        // );
        if (token.IdRegiao === 1) {
          setarCor(response.data[0].cor1, response.data[0].bg1);
          setTheme(response.data[0].cor1);
          setFiltro(response.data[0].filtro1);
        }
        if (token.IdRegiao === 2) {
          setarCor(response.data[0].cor2, response.data[0].bg2);
          setTheme(response.data[0].cor2);
          setFiltro(response.data[0].filtro2);
        }
        if (token.IdRegiao === 3) {
          setarCor(response.data[0].cor3, response.data[0].bg3);
          setTheme(response.data[0].cor3);
          setFiltro(response.data[0].filtro3);
        }
        if (token.IdRegiao === 4) {
          setarCor(response.data[0].cor4, response.data[0].bg4);
          setTheme(response.data[0].cor4);
          setFiltro(response.data[0].filtro4);
        }

        setIsActive(response.data[0].Lessons.active);
        setLesson(response.data[0].Lessons);
        setCourseName(response.data[0].courseName);
        setLoaded(true);
        setUserID(token.IdAluno);
        setLessonID(response.data[0].Lessons.idLesson);
        setCourseID(response.data[0].idCourse);
        // console.log(response.data[0].Lessons);

        //setRespostas with correctAlternative of each question
        let respostas = [];
        response.data[0].Lessons.Questoes.forEach((question) => {
          respostas.push(question.correctAlternative);
        });
        setRespostas(respostas);
        // console.log("respostas BEW", respostas);
      })
      .then(() => {
        setTelaInicial(token.Tela);
        setModalRespondido(false);
      });

    Object.keys(localStorage).forEach((el, index) => {});
    urlListarAnotacao = token.UrlAnotacaoListar;

    let bodyListar = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoListar).CodigoContrato,
      Tipo: JSON.parse(token.JsonBodyAnotacaoListar).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoListar).Parceiro,
    });

    fetch(baseURL + "anotacao", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pR889Q6+6CI9VuF",
      },
      body: JSON.stringify({
        url: urlListarAnotacao,
        bodyAnotacao: bodyListar,
        tipo: "listar",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setIndexAnotacao(() => {
          if (data.Dados.length !== 0) {
            setTemAnotacao(true);
            return data.Dados[data.Dados.length - 1].idBlocoNota;
          } else {
            return null;
          }
        });
        let anotacao =
          (data.Dados.length !== 0 &&
            data.Dados[data.Dados.length - 1].Descricao) ||
          "";
        if (anotacao !== null) {
          localStorage.setItem(`anotacoes`, anotacao);
          setAnotacaoVindaDoToken(anotacao);
        }
      });
  }, []);

  window.onbeforeunload = function () {
    if (mode !== lesson.Questoes.length + 1) {
      saveTela();
      return 1;
    }
  };

  useEffect(() => {
    if (mode > 0) {
      props.turnOffCapa();
    } else {
      props.turnOnCapa();
    }
  }, [mode]);

  useEffect(() => {
    document.title = lesson.lessonName;
  }, [lesson.lessonName]);

  const previous = () => {
    setMode(parseInt(mode) - 1);
  };

  const next = () => {
    if (isActive) {
      if (mode < lesson.Questoes.length + 1) {
        setMode(parseInt(mode) + 1);
        localStorage.setItem(
          "tela",
          parseInt(localStorage.getItem("tela")) + 1
        );
      } else {
      }
    }
  };

  const submitFile = (file) => {
    var extensao = file.name.split(/\.(?=[^\.]+$)/),
      reader = new FileReader();
    reader.readAsDataURL(file);

    const post = function (base64) {
      Axios.post(UrlArquivosAtividadePratica, {
        CodigoContratoCursoMateria: userID,
        Nome: file.name + "." + extensao[1],
        CaminhoFTP: (+new Date()).toString(36),
        Extensao: extensao[1],
        ArquivoBase64: base64,
      }).then((response) => {
        // alert("Sucesso! Enviou os arquivos");
      });
    };

    reader.onloadend = function () {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      var b64 = reader.result.replace(/^data:.+;base64,/, "");
      //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
      // const blob = base64StringToBlob(b64, file.type);
      post(b64);
    };
  };

  const [showingSendButton, setShowingSendButton] = useState(true);

  const hideSendButton = () => {
    setShowingSendButton(false);
  };

  const confirmGeral = () => {
    let i = 0;

    let fitered_localStorage_keys = [];
    let fitered_localStorage_values = [];

    Object.values(localStorage).forEach((element, index) => {
      if (
        element.includes("resposta") &&
        !Object.keys(localStorage)[index].includes("anotacoes")
      ) {
        if (
          JSON.parse(element).lessonID === lessonID &&
          JSON.parse(element).userID === userID &&
          JSON.parse(element).idAtividade === tokenInfo.IdAtividade.toString()
        ) {
          fitered_localStorage_keys.push(Object.keys(localStorage)[index]);
          fitered_localStorage_values.push(element);
          i++;
        }
      }
    });
    // console.log("fitered_localStorage_keys ", fitered_localStorage_keys);
    // console.log("fitered_localStorage_values ", fitered_localStorage_values);

    if (i === lesson.Questoes.length) {
      // hideSendButton();
      setIsLoading(true);

      let ordened_array_localStorage = [];

      fitered_localStorage_keys.forEach((value, index) => {
        let number = value.split("resposta ")[1].split(" ")[0];
        ordened_array_localStorage[number - 1] =
          fitered_localStorage_values[index];
      });

      // console.log("ordened_array_localStorage", ordened_array_localStorage);

      saveTelaFinalizando(
        ordened_array_localStorage,
        fitered_localStorage_keys
      );
    } else {
      alert("Você não respondeu todas as questões!");
    }
  };

  const saveTela = () => {
    let url = UrlApontamentos.replace("##Tela", "" + mode);
    if (mode >= 1) {
      Axios.post(url, {
        tela: mode,
      }).then((response) => {
        // alert("Enviou a tela que parou");
        // console.log("Enviou a tela que parou");
      });
    }
  };

  const saveTelaFinalizando = async (
    ordened_array_localStorage,
    fitered_localStorage_keys
  ) => {
    // let url = UrlApontamentos.replace("##Tela", parseInt(mode)).replace('/null', '/1')
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";

    // console.log("ordened_array_localStorage", ordened_array_localStorage);
    // console.log("fitered_localStorage_keys", fitered_localStorage_keys);

    let questoes = ordened_array_localStorage.map((element, index) => {
      return {
        idQuestao: JSON.parse(element).questionID,
        resposta: JSON.parse(element).resposta + 1,
        categoria: JSON.parse(element).category,
        // alternativaCorreta: gabarito.data[0].respostas[index],
        alternativaCorreta: lesson.Questoes[index].correctAlternative,
        nomeQuestao: lesson.Questoes[index].questionName,
      };
    });

    const obj_gabarito = {
      curso: courseName,
      aula: lesson.lessonName,
      aluno: userID,
      questoes: questoes,
      acertos: questoes.filter((element) => {
        return element.resposta === element.alternativaCorreta;
      }).length,
      erros: questoes.filter((element) => {
        return element.resposta !== element.alternativaCorreta;
      }).length,
      numPerguntas: lesson.Questoes.length,
      progresso: "100",
    };
    // console.log("NEW: ", obj_gabarito);

    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj_gabarito),
    })
      .then((response) => {
        if (response.type == "opaque") {
          //remover itens do localStorage que foram salvos no banco, sem apagar o resto
          fitered_localStorage_keys.forEach((element) => {
            localStorage.removeItem(element);
          });

          setIsLoading(false);
          window.location.assign(UrlEncerramentoAula);
        }
      })
      .catch(function (err) {
        if (err) {
          console.log("ERRO: ", err);
        }
      });
  };
  useEffect(() => {
    // saveTela()
    context.setModeContext(mode);
  }, [mode]);

  let urlInserirAnotacao = null;
  let urlAlterarAnotacao = null;

  useEffect(() => {
    const { token } = props;
    urlInserirAnotacao = token.UrlAnotacaoInserir;
    urlAlterarAnotacao = token.UrlAnotacaoAlterar;

    let bodyInserirAnotacao = JSON.stringify({
      CodigoContrato: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoContrato,
      CodigoMateria: JSON.parse(token.JsonBodyAnotacaoInserir).CodigoMateria,
      Tipo: JSON.parse(token.JsonBodyAnotacaoInserir).Tipo,
      Parceiro: JSON.parse(token.JsonBodyAnotacaoInserir).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoInserir).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
      IdAula: token.IdAula,
      IdAtividade: token.IdAtividade,
    });
    let bodyAlterarAnotacao = JSON.stringify({
      idBlocoNota: parseInt(indexAnotacao),
      Parceiro: JSON.parse(token.JsonBodyAnotacaoAlterar).Parceiro,
      Titulo: JSON.parse(token.JsonBodyAnotacaoAlterar).Titulo,
      Descricao: localStorage.getItem("anotacoes"),
    });
    if (temAnotacao) {
      if (
        localStorage.getItem("anotacoes") !== null &&
        localStorage.getItem("anotacoes") !== anotacaoVindaDoToken
      ) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlAlterarAnotacao,
            bodyAnotacao: bodyAlterarAnotacao,
            tipo: "alterar",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log('index que foi alterado >>>  ', parseInt(indexAnotacao) )
            // console.log('response alterado>>>', data)
            setTemAnotacao(true);
          });
      }
    } else {
      // if (listaCarregada ){
      if (indexAnotacao === null) {
        fetch(baseURL + "anotacao", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pR889Q6+6CI9VuF",
          },
          body: JSON.stringify({
            url: urlInserirAnotacao,
            bodyAnotacao: bodyInserirAnotacao,
            tipo: "inserir",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setTemAnotacao(true);
            // console.log('index da anotacao criada >>> ', data.Dados.idNota)
            setIndexAnotacao(data.Dados.idNota);
          });
      }
      // }
    }
  }, [context.isFocusAnnotation]);

  const resetLocalStorage = () => {
    setModalRespondido(true);
    localStorage.setItem("tela", 1);
    setMode(1);
    lesson.Questoes.forEach((el, index) => {
      var indexplus = index + 1;
      localStorage.removeItem(
        `${
          "resposta " +
          indexplus +
          " lessonID " +
          lesson.idLesson +
          " userID " +
          userID +
          " category " +
          categoria +
          "idAtividade " +
          tokenInfo.IdAtividade.toString()
        } `
      );
      // console.log(`${ "resposta " + indexplus + " lessonID " + lesson.idLesson + ' userID ' + userID + " category " + categoria } `)
    });
  };

  ArrowKeysReact.config({
    left: () => {
      // console.log('left key detected.');
      if (mode > 1) {
        previous();
      }
    },
    right: () => {
      // console.log('right key detected.');
      if (mode > 0 && mode < lesson.Questoes.length + 1) {
        next();
      }
    },
    up: () => {
      // console.log('up key detected.');
    },
    down: () => {
      // console.log('down key detected.');
    },
  });

  // <input type="text" value={answer} onKeyPress={handleAnswerChange} />

  const [modalToggle, setModalToggle] = useState(false);
  const enableModal = () => {
    if (modalToggle === true || telaInicial <= 1) {
      next();
    } else {
      setModalToggle(true);
      setShowingModal(true);
    }
  };

  const [showImageZoom, setShowImageZoom] = useState(false);
  const [imageFile, setImageFile] = useState();
  const toggleImageZoom = (estado, file) => {
    setImageFile(file);
    setShowImageZoom(estado);
  };

  const [mobile, setMobile] = useState("");
  useEffect(() => {
    setMobile(() => {
      if (mode >= 1) {
        return "main-mobile";
      }
    });
  }, [mode]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [iconFullScreen, setIconFullScreen] = useState(faExpandArrowsAlt);
  function openFullScreen() {
    setIsFullScreen(true);
    setIconFullScreen(faCompressArrowsAlt);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  }
  function closeFullScreen() {
    setIsFullScreen(false);
    setIconFullScreen(faExpandArrowsAlt);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  const modalStruct = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          <img style={{ width: "160px" }} src={img_continuar} alt="" />
          <p className="modal-texto" style={{ marginTop: "20px" }}>
            Deseja continuar de onde parou?
          </p>

          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              flex: "3.5",
            }}
          >
            <div
              onClick={() => {
                setMode(telaInicial);
                setModalRespondido(true);
                setShowingModal(false);
              }}
              className="modal-btn-sim"
            >
              Sim
            </div>
            <div
              onClick={() => {
                resetLocalStorage();
                setShowingModal(false);
              }}
              className="modal-btn-nao"
            >
              Não
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoading && (
        <img
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            height: "60px",
            zIndex: "9999",
          }}
          src={loadingGif}
          alt={''}
        ></img>
      )}
      <button
        className="btn-fullscreen"
        onClick={() => (isFullScreen ? closeFullScreen() : openFullScreen())}
      >
        <FontAwesomeIcon
          icon={iconFullScreen}
          style={{ transform: "scale(1.35)" }}
        ></FontAwesomeIcon>
      </button>

      {/* {loaded && (
        <div>
          <Background props={{}} />
        </div>
      )} */}

      <div className="teste-conhecimentos">
        <div hidden={false} id="modal">
          {loaded && <>{modalToggle && !modalRespondido && modalStruct()}</>}
        </div>

        {mode !== 0 && (
          <Title props={{ theme, courseName, lesson, lesson, contador }} />
        )}
        {loaded ? (
          <>
            {isActive === 1 && lesson.Questoes !== null ? (
              <>
                {mode > 0 &&
                mode < lesson.Questoes.length + 1 &&
                lesson.Questoes[mode - 1].Questionimage ? (
                  <div hidden={!showImageZoom}>
                    <div
                      onClick={() => toggleImageZoom(false)}
                      style={{
                        backdropFilter: " blur(8px) saturate(170%)",
                        zIndex: "1000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        background: "rgba(0, 0, 0, 0.7)",
                        width: "100vw",
                        height: "100vh",
                      }}
                    >
                      <div
                        style={{
                          zIndex: "1001",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "25px",
                          textAlign: "center",
                          padding: "30px",
                          minWidth: "500px",
                          minHeight: "200px",
                        }}
                      >
                        <h3 style={{ fontSize: "18px" }}>
                          {
                            lesson.Questoes[mode - 1].Questionimage[0]
                              .questionImageName
                          }
                        </h3>

                        <h1
                          style={{
                            top: "6%",
                            right: "20%",
                            position: "absolute",
                            fontSize: "2em",
                          }}
                        >
                          <button
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItem: "center",
                            }}
                            onClick={() => toggleImageZoom(false)}
                          >
                            X
                          </button>
                        </h1>

                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="imagem_conteudo"
                            src={`data: image / png; base64, ${imageFile} `}
                            alt={"alternativa"}
                          ></img>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <main id="conteudo" className={mobile}>
                  <section className="section-left">
                    <div className="section-left-1 items">
                      {mode === 0 && loaded && (
                        <CapaTesteSeusConhecimentos
                          props={{
                            theme,
                            lesson,
                            courseName,
                            contador,
                            progress,
                            mode,
                          }}
                        />
                      )}
                      {mode > 0 && (
                        <ProgressBar
                          props={{
                            start: false,
                            theme,
                            mode,
                            lesson,
                            progress,
                          }}
                        />
                      )}
                      {mode > 0 && <Anotacoes />}
                    </div>

                    <div className="section-left-2 items">
                      {mode === 0 && loaded && (
                        <button
                          hidden={showingModal}
                          className="btn-comecar-left"
                          onClick={() => enableModal()}
                        >
                          COMEÇAR
                        </button>
                      )}
                    </div>
                  </section>

                  {mode > 0 && (
                    <>
                      <div style={{ pointerEvents: "none" }} className="centro">
                        <div className="centro2">
                          <h1></h1>
                        </div>
                        <div style={{ marginLeft: "5px" }}>
                          <h2
                            style={{
                              zIndex: "5",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              aligItems: "center",
                              color: "white",
                              transition: "all 1s",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50px",
                              background: theme,
                              position: "absolute",
                              marginTop: "55px",
                              fontSize: "20px",
                            }}
                          >
                            {mode !== lesson.Questoes.length + 1 ? (
                              <>{mode}</>
                            ) : (
                              <>
                                {!confirmouGeral &&
                                lesson.mm_avaliativo === 1 ? (
                                  <>
                                    {/* <AlertCircleIcon size={50} /> */}
                                    <FontAwesomeIcon
                                      style={{
                                        margin: "auto",
                                        fontSize: "24px",
                                      }}
                                      icon={faExclamation}
                                    ></FontAwesomeIcon>
                                  </>
                                ) : (
                                  <>
                                    <CheckIcon size={48} />
                                  </>
                                )}
                              </>
                            )}
                          </h2>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    {mode > 0 ? (
                      <>
                        {mode > 0 ? (
                          <>
                            <button
                              {...ArrowKeysReact.events}
                              tabIndex="1"
                              className="nav_buttons  nav_buttons_left"
                              style={{
                                border: "4px solid rgba(211,211,211,0.3)",
                                zIndex: "4",
                                fontWeight: "800",
                                fontSize: "1.8em",
                                borderRadius: "50%",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                background: { theme },
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => previous()}
                            >
                              <FontAwesomeIcon
                                icon={faAngleLeft}
                              ></FontAwesomeIcon>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                        {mode < lesson.Questoes.length + 1 ? (
                          <>
                            <button
                              {...ArrowKeysReact.events}
                              tabIndex="1"
                              className="nav_buttons nav_buttons_right"
                              style={{
                                border: "4px solid rgba(211,211,211,0.3)",
                                zIndex: "4",
                                fontWeight: "800",
                                fontSize: "1.8em",
                                borderRadius: "50%",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                background: { theme },
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => next()}
                            >
                              <FontAwesomeIcon
                                icon={faAngleRight}
                              ></FontAwesomeIcon>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <section className="section-right">
                    {mode === 0 && loaded && (
                      <div className="tela-inicial-right">
                        <CapaTesteSeusConhecimentos
                          props={{
                            theme,
                            lesson,
                            courseName,
                            contador,
                            progress,
                            mode,
                          }}
                        />
                        <button
                          className="btn-comecar-right"
                          onClick={() => enableModal()}
                        >
                          COMEÇAR
                        </button>
                      </div>
                    )}
                    <div>
                      {mode <= lesson.Questoes.length ? (
                        <>
                          <TC_perguntas
                            props={{
                              respostas,
                              toggleImageZoom,
                              confirmGeral,
                              userID,
                              submitFile,
                              theme,
                              mode,
                              next,
                              lesson,
                              setSelectedAlternative,
                              selectedAlternative,
                              previous,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <div className="tela-final">
                            <div className="tela-final-mensagem">
                              <p style={{ marginLeft: "40px" }}>
                                Você pode confirmar o envio de suas respostas
                                com este botão, ou voltar para revisar as
                                questões.
                              </p>
                            </div>
                            <div style={{}}>
                              <button
                                hidden={!showingSendButton}
                                className="btn-comecar-left"
                                onClick={() => {
                                  confirmGeral();
                                }}
                              >
                                ENVIAR RESPOSTAS
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </main>
              </>
            ) : (
              <>
                <h1 style={{ color: "red", fontSize: "100px" }}>
                  Questões desativas pelo Admin
                </h1>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TesteConhecimentos;
