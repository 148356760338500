import React, { useEffect, useState } from "react";
import "./AtividadeDuplo.css";
import AtividadeSimples from "./AtividadeSimples";
import ReactHtmlParser from "react-html-parser";
// import useSound from "use-sound";
import { Audio_Icon } from "../../../svg_files/audio_icon";

const AtividadeDuplo = (props) => {
  const { lesson } = props;
  const { mode } = props;
  const { otherData } = props;
  const { titleProps } = props;
  const { provao } = props;

  const questionName = props.lesson.Questoes[mode - 1].questionName;
  const audioPergunta = props.lesson.Questoes[mode - 1].audio;
  const audioIntro = props.lesson.Questoes[mode - 1].audioIntro;
  const questionNameIntro = props.lesson.Questoes[mode - 1].questionNameIntro;
  const questionNameIntroContent =
    props.lesson.Questoes[mode - 1].questionNameIntroContent;
  const timerIntro = props.lesson.Questoes[mode - 1].timerIntro;

  // const [playSound] = useSound(audioIntro);
  const [showQuestion, setShowQuestion] = useState(false);

  const [alreadyTimedOut, setAlreadyTimedOut] = useState([]);

  useEffect(() => {
    //show question after timerIntro
    if (alreadyTimedOut.includes(mode)) return;
    setShowQuestion(false);
    setTimeout(() => {
      setShowQuestion(true);
      setAlreadyTimedOut([...alreadyTimedOut, mode]);
    }, timerIntro * 1000);
  }, [mode]);

  // console.log("audioIntro: ", audioIntro);
  // console.log("audioPergunta: ", audioPergunta);

  return (
    <div className="container_atividade_duploEnsinaMais">
      {questionNameIntro != null &&
      questionNameIntro != "" &&
      questionNameIntro != undefined ? (
        <>
          <AtividadeSimples
            style={{}}
            lesson={lesson}
            mode={mode}
            titleProps={titleProps}
            otherData={otherData}
            questionTitle={questionNameIntro}
            questionSound={audioIntro}
            hasQuestions={false}
            introContent={questionNameIntroContent}
            singular={showQuestion ? false : true}
          />

          {showQuestion && (
            <AtividadeSimples
              style={{}}
              lesson={lesson}
              mode={mode}
              titleProps={titleProps}
              otherData={otherData}
              questionTitle={questionName}
              questionSound={audioPergunta}
              hasQuestions={true}
              singular={false}
            />
          )}
        </>
      ) : (
        <>
          <AtividadeSimples
            lesson={lesson}
            mode={mode}
            titleProps={titleProps}
            otherData={otherData}
            questionTitle={questionName}
            questionSound={audioPergunta}
            hasQuestions={true}
            singular={true}
          />
        </>
      )}
    </div>
  );
};

export default AtividadeDuplo;
