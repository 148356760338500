import React from "react";
import Title2 from "../Title/Title2";
import ProgressBar from "../_ProgressBar/ProgressBar";
import "./CapaHoraDoLivro.css";
import TitleEnsinaMaisCapa from "../Title/TitleEnsinaMaisCapa";
import { transcode } from "buffer";
import { Arrow_Icon } from "../../svg_files/arrow_icon";

const Capa = ({ props }) => {
  const theme = props.theme;
  const lesson = props.lesson;
  const courseName = props.courseName;
  const timer = props.timer;
  const contador = props.contador;
  const progress = props.progress;
  const mode = props.mode;
  const enableModal = props.enableModal;
  const showingModal = props.showingModal;
  const Finalizar = props.Finalizar;

  console.log(Finalizar);

  const provao = props.provao;

  return (
    <>
      <section
        style={{
          height: "75vh",
          width: "85vw",
          minHeight: "50vh",
          backgroundColor: `rgba(255, 0, 0, 0.5)`,
        }}
        className="capa-ensisna-mais"
      >
        <div className="capa-ensina-mais">
          <TitleEnsinaMaisCapa
            props={{
              provao,
              theme,
              courseName,
              lesson,
              timer,
              contador,
              enableModal,
              showingModal,
            }}
          >
            {" "}
          </TitleEnsinaMaisCapa>
          <div className="title-tcEnsinaMais">{"HORA DO LIVRO"}</div>
          {/* <ProgressBar props={{ start: true, theme, mode, lesson, progress }} /> */}
           
          <div className="texto-capaEnsinaMais">
            PARABÉNS!
            <br></br>
            VOCÊ CHEGOU ATÉ AQUI, COMEMORE TODAS AS SUAS CONQUISTAS.
            <br></br>
            <br></br>
            ABRA SEU LIVRO DIDÁTICO NO FINAL DA AULA E RESPONDA AS QUESTÕES
            INDICADAS.
          </div>
          <button
            style={{ backgroundColor: "red" }}
            hidden={showingModal}
            className="btn-comecarEnsinaMais"
            onClick={() => Finalizar()}
          >
            VAMOS LÁ! <Arrow_Icon />
          </button>
        </div>
      </section>

      <section
        style={{
          height: "12vh",
          width: "85vw",
        }}
      />
    </>
  );
};

export default Capa;
