import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import '../../../App.css'
import UploadButton from './../../_Upload/UploadButton';
import Context from '../../../state/Context'

import { Upload as UploadIcon } from 'tabler-icons-react';




const FileUpload = ({ props }) => {
  const [chosenFile, setChosenFile] = useState('none')
  const theme = props.theme

  const setSending = props.setSending
  const sending = props.sending

  const SubmitFile = styled.div`
        margin-top:40px;
        display:${props => props.display};    
    `
  useEffect(() => {

    // console.log("mode", props.mode)
  }

    , [])

  const submitFile = props.submitFile
  const context = useContext(Context)

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [displayArquivo, setDisplayArquivo] = useState('none')
  const [textBtn, setTextBtn] = useState('UPLOAD')

  const changeHandler = (event) => {
    setDisplayArquivo('flex')
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    // console.log('clicou')

  };
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
  }
  const [otherFile, setOtherFile] = useState(false)

  const handleSubmission = () => {

  };

  return (

    <div style={{
      width: "fit-content",
      margin: "auto"
    }}>

      {isFilePicked ? (
        <div style={{ display: displayArquivo, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

          <div style={{ display: "flex", flexDirection: "row", background: "white", width: "450px", padding: "12px", borderRadius: '20px', margin: "auto", marginBottom: "30px" }}>
            {!context.uploadSuccessful ? <>
              <span style={{ width: "96.5%", display: 'flex', flexDirection: "column" }}>
                <h2 style={{ color: "rgb(40, 40, 40)" }}>Arquivo: </h2>{selectedFile.name}
                {/* <p>Filetype: {selectedFile.type}</p> */}
                <h2 style={{ color: 'rgb(40, 40, 40)' }}>Tamanho: </h2>{(() => formatSizeUnits(selectedFile.size))()}
              </span>
            </> : <>
              <span style={{ width: "96.5%", display: 'flex', flexDirection: "column" }}>
                <h2 style={{ color: "rgb(40, 40, 40)" }}> </h2>
                <h1 style={{ margin: "auto" }}>Arquivo enviado com sucesso</h1>
                {/* <p>Filetype: {selectedFile.type}</p> */}
                <h2 style={{ color: 'rgb(40, 40, 40)' }}></h2>
              </span>
            </>}
            <div>
              <button style={{ fontFamily: "sans-serif", width: "3.5%", alignSelf: "flex-end", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", padding: "0", border: "none", width: "30px", height: "30px" }} onClick={() => { setSending(false); setDisplayArquivo('none'); setIsFilePicked(false); context.setUploadSuccessful(false); setOtherFile(true) }}>X</button>
            </div>
            {/* lastModifiedDate:{' '}
            {selectedFile.lastModifiedDate.toLocaleDateString()} */}

          </div>
          {!context.uploadSuccessful ? <>
            {!sending ? (
              <>
                <span className="btn-comecar-left-final" onClick={() => { setSending(true); submitFile(selectedFile); setIsFilePicked(true); props.mode = props.mode }} style={{ cursor: 'pointer', width: "fit-content", display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <label htmlFor="upload" style={{ cursor: 'pointer', display: 'flex', flexDirection: "column", alignItems: "center" }} >
                    <UploadIcon size={60} />
                    {otherFile ? "ENVIAR OUTRO ARQUIVO" : "ENVIAR ARQUIVO"}
                  </label>
                </span>
              </>
            ) : (
              <>
                enviando
              </>)}
          </> : <></>}
        </div>
      ) : (<>
        <label htmlFor="upload" style={{ cursor: 'pointer', display: 'flex', flexDirection: "column", alignItems: "center" }} >
          <span className="btn-comecar-left-final" style={{ width: "fit-content", display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <UploadIcon style={{}} size={60} />
            UPLOAD
            <input style={{ display: 'none' }} id="upload" type="file" name="file" onChange={changeHandler} />
          </span>
        </label>

      </>
        // <p >Select a file to show details</p>
      )
      }
    </div >
  )
}

export default FileUpload
