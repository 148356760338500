import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import './AcesseMM.css'

const AcesseMM = ({ props }) => {

    const lesson = props.lesson
    const mode = props.mode


    const getFile = props.getFile;
    const theme = props.theme;


    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className='balao_acesse' >

            {
                lesson.Questoes[mode - 1].acesse.split('<$')[0] &&
                <h1>{lesson.Questoes[mode - 1].acesse.split('<$')[0]}</h1>
            }

            < a target="_blank" href={lesson.Questoes[mode - 1].acesse.split('<$')[1].split('$>')[0]} > Acesse o Link</a>

            <br></br><br></br>
            {
                lesson.Questoes[mode - 1].acesse.split('$>')[1] &&
                <h1>{lesson.Questoes[mode - 1].acesse.split('$>')[1]}</h1>
            }



        </div >
    )
}

export default AcesseMM
