import React from "react";

export const Audio_Icon = () => {
  return (
    <svg
      width="50"
      height="42"
      viewBox="0 0 50 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="42" fill="black" />
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M29.0769 17.3333C29.0769 17.3333 31.3077 18.381 31.3077 21C31.3077 23.619 29.0769 24.6667 29.0769 24.6667M31.3077 11.0476C35.7692 13.1429 38 16.2857 38 21C38 25.7143 35.7692 28.8571 31.3077 30.9524M9 16.2857V25.7143H14.5769L23.5 32V10L14.5769 16.2857H9Z"
          stroke="#F7CB6B"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="7.75"
          y="8.74999"
          width="32.5"
          height="25.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
