import React, { useState, useEffect } from "react";
import './ProgressBar.css'


const ProgressBarPR = ({ props }) => {


    const theme = props.theme


    const [barWidth, setBarWidth] = useState('30%')
    const [barFill, setBarFill] = useState('100%')



    // 

    const containerStyles = {

        height: 5,
        width: barWidth,
        backgroundColor: "#C9C1C9",
        borderRadius: 10,
    }
    const fillerStyles = {
        height: '100%',
        width: barFill,
        backgroundColor: theme,
        borderRadius: 'inherit',
        textAlign: 'right'
    }
    const labelStyles = {
        padding: 5,
        fontSize: '0.6em',
        color: 'white',
        // fontWeight: 'bold',
        backgroundColor: theme,
        borderRadius: "200px"

    }

    return (
        <div >

            <div style={containerStyles}>
                <div style={fillerStyles}>

                    {/* <span style={labelStyles}>{`${mode}/${quantidadePerguntas}`}</span>
                    <span style={labelStyles}>{`${progress}/${quantidadePerguntas}`}</span> */}
                </div>
            </div>

        </div >
    );
};

export default ProgressBarPR;