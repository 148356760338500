import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import AcesseMM from "../_AcesseMM/AcesseMM";
import Definicao from "../_Definicao/Definicao";
import Dica from "../_Dica/Dica";
import Importante from "../_Importante/Importante";
import "./TC_perguntas.css";
import FileUpload from "./Tipos/FileUpload";
import Objetiva from "./Tipos/Objetiva";

const TC_perguntas = ({ props }) => {
  const mode = props.mode;
  const lesson = props.lesson;
  const selectedAlternative = props.selectedAlternative;
  const setSelectedAlternative = props.setSelectedAlternative;
  const theme = props.theme;
  const submitFile = props.submitFile;
  const userID = props.userID;
  const questionID = props.questionID;
  const respostas = props.respostas;
  const setSending = props.setSending;
  const sending = props.sending;

  return (
    <div>
      {mode !== 0 && (
        <div className="content-questions">
          {lesson.Questoes[mode - 1].category === 1 ? (
            <>
              <header id="foo" className="question-name-tc">
                <div style={{}}>
                  <>
                    {/* {lesson.Questoes[mode - 1].questionName.split('<$')[0]} */}
                    <p
                      style={{
                        textAlign: "justify",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {/* <h1>{lesson.Questoes[mode - 1].questionName}</h1> */}
                      <>
                        {ReactHtmlParser(
                          lesson.Questoes[mode - 1].questionName
                        )}
                      </>
                    </p>
                  </>

                  {lesson.Questoes[mode - 1].acesse ? (
                    <>
                      <AcesseMM
                        props={{ lesson: lesson, mode: mode }}
                      ></AcesseMM>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].dica ? (
                    <>
                      <Dica props={{ lesson: lesson, mode: mode }}></Dica>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].importante ? (
                    <>
                      <Importante
                        props={{ lesson: lesson, mode: mode }}
                      ></Importante>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].definicao ? (
                    <>
                      sdsds
                      <Definicao
                        props={{ lesson: lesson, mode: mode }}
                      ></Definicao>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </header>
            </>
          ) : (
            <>
              <header className="question-name">
                <div style={{}}>
                  <p id="foo" style={{ whiteSpace: "pre-line" }}>
                    {/* <h1>{lesson.Questoes[mode - 1].questionName}</h1> */}
                    {ReactHtmlParser(lesson.Questoes[mode - 1].questionName)}
                  </p>

                  {lesson.Questoes[mode - 1].acesse ? (
                    <>
                      <AcesseMM
                        props={{ lesson: lesson, mode: mode }}
                      ></AcesseMM>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].dica ? (
                    <>
                      <Dica props={{ lesson: lesson, mode: mode }}></Dica>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].importante ? (
                    <>
                      <Importante
                        props={{ lesson: lesson, mode: mode }}
                      ></Importante>
                    </>
                  ) : (
                    <></>
                  )}

                  {lesson.Questoes[mode - 1].definicao ? (
                    <>
                      <Definicao
                        props={{ lesson: lesson, mode: mode }}
                      ></Definicao>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </header>
            </>
          )}

          <div className="alternatives-container">
            {lesson.Questoes[mode - 1].questionType === 1 ? (
              <Objetiva
                props={{
                  respostas,
                  userID,
                  questionID,
                  theme,
                  lesson,
                  mode,
                  setSelectedAlternative,
                  selectedAlternative,
                }}
              />
            ) : (
              <></>
            )}
          </div>

          {lesson.Questoes[mode - 1].questionType === 10 ? (
            <FileUpload
              props={{
                mode: mode,
                theme,
                userID,
                submitFile,
                setSending,
                sending,
              }}
            ></FileUpload>
          ) : (
            <></>
          )}

          <span
            style={{
              position: "relative",
              zIndex: "3",
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 00px",
            }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default TC_perguntas;

