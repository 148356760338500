import React from "react";

export const ErrorModal = (props) => {
    return (
        <>
        {props.errorMessage && <div className="App">
            <div className="error-modal">
                <div className="image-container">
                    <img src="/warning-icon.png" alt="Paris"/>
                </div>
                <div className="text-container" style={{ whiteSpace: 'pre-line' }}>
                    <h1>{props.errorMessage}</h1>
                </div>
            </div>
        </div>}
        </>
    )
}