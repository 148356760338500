        import React from "react";
        import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
        import App from "./App.js";
        import Render from "./Render.js";
        import Close from "./pages/Close";

        const RouterPages = () => {
          return (
            <Router>
              <Switch>
                <Route exact path={"/:token"} component={App} />
                <Route exact path={"/acessos"} component={Render} />
                <Route exact path={"/close/close"} component={Close} />
              </Switch>
            </Router>
          );
        };

        export default RouterPages;
