import React from "react";
import "./TitleEnsinaMaisCapa.css";

const TitleEnsinaMais = ({ props }) => {
  const courseName = props.courseName;
  const lesson = props.lesson;
  const timer = props.timer;
  const theme = props.theme;
  const contador = props.contador;
  const provao = props.provao;
  return (
    <div className="titleEnsinaMaisCapa">
      {courseName.toUpperCase() + " | " + lesson.lessonName.toUpperCase()}
    </div>
  );
};

export default TitleEnsinaMais;
