import React, { useState } from 'react'
import './Acesse.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import btn_download from './btn_download.png'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import { AlertTriangle as AlertTriangleIcon } from 'tabler-icons-react';

const Acesse = ({ props }) => {

    const lesson = props.lesson;
    const getFile = props.getFile;
    const theme = props.theme;
    const mode = props.mode;

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);


    return (
        <div >

            {mode === 0 ? (
                <>
                    {/* <div style={{}}>
                        <div className={isOpen ? 'm-menu -active' : 'm-menu '} onMouseEnter={toggle} onMouseLeave={toggle}>
                            <div className="acesseIcon">
                                <AlertTriangleIcon style={{ marginLeft: '30px', marginTop: '10px' }} size={60} color={theme} />
                            </div>
                            <div className="m-menu__list">
                                {lesson.acesse.split('##').length > 0 ? (
                                    <>
                                        <div className='acesse-popup-text' style={{ textAlign: 'justify', }}>

                                            <h1>
                                                {lesson.acesse.split('##')[0]}
                                                <a style={{ cursor: 'pointer', color: theme }} onClick={() => getFile()}>{lesson.acesse.split('##')[1]}</a>
                                                {lesson.acesse.split('##')[2]}
                                            </h1>

                                        </div>
                                    </>
                                ) : (<>
                                </>)

                                }

                            </div>

                        </div>



                    </div> */}

                    <>
                        <div className="download_space">

                            {/* Clique Aqui para fazer o download do arquivo de apoio que você utilizará no decorrer desta aula. */}

                            {/* {lesson.acesse.split('##')[0]}
                            <a style={{ cursor: 'pointer', color: theme }} onClick={() => getFile()}>{lesson.acesse.split('##')[1]}</a>
                            {lesson.acesse.split('##')[2]} */}

                            <p>{ReactHtmlParser(lesson.acesse)}</p>

                        </div>


                    </>
                </>
            ) : (
                <>


                </>
            )}



        </div >

    )
}




export default Acesse


