/* eslint-disable react/jsx-pascal-case */
// import './MaoMassa.css';
import "./HoraDoLivro.css";
import React, { useState, useEffect, useContext } from "react";
import { useTimer, useCourse, useTheme } from "../../state/Provider";
import { getCourses, submitResposta, validate } from "../../services";

import Title from "../../components/Title/Title";
import Background from "../../components/Background/Background";
import logo from "./mm_img.png";
import { useBaseURLs } from "../../state/Provider";

import Acesse from "../../components/_Acesse/Acesse";

import CapaPR from "../../components/PenseResponda/CapaPR";
import CapaHoraDoLivro from "../../components/HoraDoLivro/CapaHoraDoLivro";

import fundo_ensinamais from "../../Files/fundo_ensinamais.png";

import footer_img from "../../Files/footer_mauricio.png";

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

function HoraDoLivro(props) {
  const [showEmbed, setShowEmbed] = useState(false);
  var token = null;
  const [userID, setUserID] = useState("");
  const [lessonID, setLessonID] = useState("");
  const [progress, setProgress] = useState(0);
  var [isActive, setIsActive] = useState();
  const [textResponse, setTextResponse] = useState("");

  const categoria = 2;

  var contador = null;
  const { courseName, setCourseName, lesson, setLesson } = useCourse();

  const { theme, setTheme } = useTheme();
  const [testList, setTestList] = useState([]);
  const [mode, setMode] = useState(0);
  const { timer, setTimer } = useTimer(3);
  const [loaded, setLoaded] = useState(false);
  const [backgroundEmbedID, setBackgroundEmbedID] = useState("");
  const [capaEmbedID, setCapaEmbedID] = useState("");
  var tempo = null;

  const [confirmouGeral, setConfirmouGeral] = useState(false);

  const [modalTela, setModalTela] = useState("flex");
  const [modalRespondido, setModalRespondido] = useState(false);

  const [UrlArquivosAtividadePratica, setUrlArquivosAtividadePratica] =
    useState("");
  const [UrlApontamentos, setUrlApontamentos] = useState("");
  const [UrlEncerramentoAula, setUrlEncerramentoAula] = useState("");
  let tela = false;

  const [telaInicial, setTelaInicial] = useState(0);

  if (
    localStorage.getItem("tela") !== undefined &&
    localStorage.getItem("tela") !== null
  ) {
    tela = parseInt(localStorage.getItem("tela"));
  }

  var root = document.querySelector(":root");
  // var rootStyles = getComputedStyle(root);

  useEffect(() => {
    const { token } = props;
    const { themeGeral } = props;
    const { setarCor } = props;
    const { setFiltro } = props;
    // console.log('token:', token)
    setTheme(themeGeral);
    // console.log('token:', token)
    setUrlArquivosAtividadePratica(token.UrlArquivosAtividadePratica);
    setUrlApontamentos(token.UrlApontamentos);
    setUrlEncerramentoAula(token.UrlEncerramentoAula);

    var root = document.querySelector(":root");
    root.style.setProperty("--backGroundImage", `url(${fundo_ensinamais})`);

    // setTheme(token.theme)

    getCourses(token.IdMateria, token.IdAula, categoria)
      .then((response) => {
        setarCor(response.data[0].cor1, response.data[0].bg1);
        setTheme(response.data[0].cor1);
        // var deg = rootStyles.getPropertyValue('--themeColor');
        // setsetCapaEmbedID(response.data[0].Lessons.background)

        if (token.IdRegiao === 1) {
          setarCor(response.data[0].cor1, response.data[0].bg1);
          setTheme(response.data[0].cor1);
          setFiltro(response.data[0].filtro1);
        }
        if (token.IdRegiao === 2) {
          setarCor(response.data[0].cor2, response.data[0].bg2);
          setTheme(response.data[0].cor2);
          setFiltro(response.data[0].filtro2);
        }
        if (token.IdRegiao === 3) {
          setarCor(response.data[0].cor3, response.data[0].bg3);
          setTheme(response.data[0].cor3);
          setFiltro(response.data[0].filtro3);
        }
        if (token.IdRegiao === 4) {
          setarCor(response.data[0].cor4, response.data[0].bg4);
          setTheme(response.data[0].cor4);
          setFiltro(response.data[0].filtro4);
        }

        setTestList(response.data[0]);
        setIsActive(response.data[0].Lessons.active);
        setLesson(response.data[0].Lessons);
        tempo = parseFloat(response.data[0].Lessons.timer);
        setCourseName(response.data[0].courseName);
        setLoaded(true);
        setUserID(token.IdAluno);
        setLessonID(token.IdAula);
      })
      .then(() => {});
  }, []);

  useEffect(() => {
    document.title = lesson.lessonName;
    // console.log(lesson)
  }, [lesson.lessonName]);

  const Finalizar = () => {
    let url = UrlApontamentos.replace("##Tela", parseInt(mode)) + "/1";
    fetch(url, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.type == "opaque") {
          localStorage.clear();
          console.log("redirecionando");
          window.location.assign(UrlEncerramentoAula);
        }
      })
      .catch(function (err) {
        if (err) {
          // console.log("ERRO: ", err)
        }
      });
  };

  return (
    <div>
      <div className="teste-conhecimentos-ensina-mais">
        {/* {mode !== 0 && (
        // <Title props={{ theme, courseName, lesson, lesson, contador }} />
        <TitleEnsinaMais props={{ theme, courseName, lesson, contador }} />
      )} */}

        {loaded ? (
          <>
            {isActive === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  position: "relative",
                }}
              >
                <div id="conteudo">
                  {mode === 0 ? (
                    <>
                      <CapaHoraDoLivro
                        props={{
                          theme,
                          lesson,
                          courseName,
                          contador,
                          progress,
                          mode,
                          Finalizar,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <footer
                    style={{
                      // height: "4vh",
                      width: "65vw",
                      cursor: "default",
                      position: "fixed",
                      bottom: "0",
                    }}
                  >
                    <img src={footer_img}></img>
                  </footer>
                </div>
              </div>
            ) : (
              <>
                <h1 style={{ color: "red", fontSize: "100px" }}>
                  Questões desativas pelo Admin
                </h1>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default HoraDoLivro;
