import React from "react";
import Title2 from "../Title/Title2";
import ProgressBar from "../_ProgressBar/ProgressBar";
import "./CapaTesteSeusConhecimentosEnsinaMais.css";
import TitleEnsinaMaisCapa from "../Title/TitleEnsinaMaisCapa";
import { transcode } from "buffer";
import { Arrow_Icon } from "../../svg_files/arrow_icon";

const Capa = ({ props }) => {
  const theme = props.theme;
  const lesson = props.lesson;
  const courseName = props.courseName;
  const timer = props.timer;
  const contador = props.contador;
  const progress = props.progress;
  const mode = props.mode;
  const enableModal = props.enableModal;
  const showingModal = props.showingModal;

  const provao = props.provao;

  return (
    <>
      <section
        style={{
          height: "75vh",
          width: "85vw",
          minHeight: "50vh",
          backgroundColor: !provao
            ? `rgba(0, 130, 0, 0.3)`
            : `rgba(255, 2555, 0, 0.4)`,
        }}
        className="capa-ensisna-mais"
      >
        <div className="capa-ensina-mais">
          <TitleEnsinaMaisCapa
            props={{
              provao,
              theme,
              courseName,
              lesson,
              timer,
              contador,
              enableModal,
              showingModal,
            }}
          >
            {" "}
          </TitleEnsinaMaisCapa>
          <div className="title-tcEnsinaMais">
            {provao ? "AVALIAÇÃO FINAL" : "DESAFIO DO DIA"}
          </div>
          <ProgressBar props={{ start: true, theme, mode, lesson, progress }} />
          {provao ? (
            <>
              <div className="texto-capaEnsinaMais">
                <br></br>
                AGORA É A HORA DE MOSTRAR TUDO O QUE VOCÊ APRENDEU!
                <br></br>
                <br></br>
                RESPONDA AS QUESTÕES A SEGUIR MARCANDO A ALTERNATIVA CORRETA EM
                CADA UMA DELAS.
              </div>
              <button
                style={{ backgroundColor: !provao ? "#459B24" : "#FFD700" }}
                hidden={showingModal}
                className="btn-comecarEnsinaMais"
                onClick={() => enableModal()}
              >
                VAMOS LÁ! <Arrow_Icon />
              </button>
            </>
          ) : (
            <>
              <div className="texto-capaEnsinaMais">
                <br></br>
                PARABÉNS!
                <br></br>
                QUE BOM QUE VOCÊ VOLTOU! VAMOS PARA UM NOVO DESAFIO?
                <br></br>
                <br></br>
                RESPONDA AS QUESTÕES A SEGUIR MARCANDO A ALTERNATIVA CORRETA EM
                CADA UMA DELAS.
              </div>
              <button
                style={{ backgroundColor: !provao ? "#459B24" : "#FFD700" }}
                hidden={showingModal}
                className="btn-comecarEnsinaMais"
                onClick={() => enableModal()}
              >
                VAMOS LÁ! <Arrow_Icon />
              </button>
            </>
          )}
           
        </div>
      </section>

      <section
        style={{
          height: "12vh",
          width: "85vw",
        }}
      />
    </>
  );
};

export default Capa;
