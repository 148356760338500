import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./ObjetivaEnsinaMais.css";
import { Audio_Icon_Alternative } from "../../../svg_files/audio_icon_alternative";
import useSound from "use-sound";

const ObjetivaEnsinaMais = ({ props }) => {
  const lesson = props.lesson;
  const mode = props.mode;
  const setSelectedAlternative = props.setSelectedAlternative;
  const selectedAlternative = props.selectedAlternative;
  const alfabeto = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  const theme = props.theme;
  const cor_alternativa_correta = "#adf802";
  const cor_alternativa_errada = "tomato";

  const AudioButton = (audio) => {
    const [playSound] = useSound(audio.audio);
    return (
      <>
        {audio.audio && (
          <span
            className="circle_audio"
            onClick={() => {
              console.log(audio.audio);
              playSound();
            }}
          >
            <Audio_Icon_Alternative />
            {/* <FontAwesomeIcon icon={faCorrect} style={{ transform: "scale(1.35)" }}></FontAwesomeIcon> */}
          </span>
        )}
      </>
    );
  };

  let respostas;
  if (props.respostas) {
    respostas = props.respostas;
  }

  function qualquer() {
    var cor;
    var respostaCorreta;

    if (respostas[mode - 1] === selectedAlternative + 1) {
      cor = cor_alternativa_correta;
      // console.log("RESPONDEU CERTO");
      respostaCorreta = true;
    } else {
      // console.log("RESPONDEU ERRADO");
      cor = cor_alternativa_errada;
      respostaCorreta = false;
    }

    return { cor, respostaCorreta };
  }

  const item = `${
    "resposta " +
    mode +
    " lessonID " +
    lesson.idLesson +
    " userID " +
    props.userID +
    " category " +
    lesson.Questoes[mode - 1].category +
    " idAtividade " +
    localStorage.getItem("idAtividade")
  }`;

  useEffect(() => {
    setSelectedAlternative(-1);

    if (JSON.parse(localStorage.getItem(item)) !== null) {
      setSelectedAlternative(JSON.parse(localStorage.getItem(item)).resposta);
      // console.log('selectedAlternative', selectedAlternative)
      Responder();
    }
  }, [mode]);

  const [respondeuEsseMode, setRespondeuEsseMode] = useState([false]);
  const Alternativas = (index, correto, audio) => {
    // console.log("mode", mode)
    // console.log("index", index)
    // console.log("correto", correto)
    // console.log("respondeuEsseMode[mode]", respondeuEsseMode[mode])
    // console.log("selectedAlternative", selectedAlternative)

    // console.log(lesson.Questoes[mode - 1]);

    if (!respondeuEsseMode[mode]) {
      return (
        <span className="circle_ensina_mais">
          <p style={selectedAlternative === index ? {} : {}}>
            {" "}
            {alfabeto[index]}
          </p>
          {/* <FontAwesomeIcon icon={faCorrect} style={{ transform: "scale(1.35)" }}></FontAwesomeIcon> */}
        </span>
      );
    } else {
      return (
        <span
          className="circle_ensina_mais"
          style={
            selectedAlternative === index
              ? { backgroundColor: correto.cor }
              : {}
          }
        >
          <p>{alfabeto[index]}</p>
          {selectedAlternative === index ? (
            correto.respostaCorreta ? (
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: cor_alternativa_correta,
                  position: "absolute",
                  right: "100",
                  transform: "scale(3)",
                }}
              ></FontAwesomeIcon>
            ) : (
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  color: cor_alternativa_errada,
                  position: "absolute",
                  right: "100",
                  transform: "scale(3)",
                }}
              ></FontAwesomeIcon>
            )
          ) : (
            <></>
          )}
        </span>
      );
    }
  };

  const Responder = () => {
    // let newArr = [...respondeuEsseMode]; // copying the old datas array
    // newArr[mode] = true; // replace e.target.value with whatever you want to change it to
    // setRespondeuEsseMode(newArr);
    respondeuEsseMode[mode] = true;
    // console.log("respondeu a questao", mode + 1)
  };

  return (
    <div className="objetivaEnsinaMais">
      {lesson.Questoes[mode - 1] !== null &&
        lesson.Questoes[mode - 1].Alternativas !== null &&
        lesson.Questoes[mode - 1].Alternativas.map((alternativa, index) => {
          return (
            <div key={index}>
              <div
                style={
                  respondeuEsseMode[mode] && selectedAlternative === index
                    ? !qualquer().respostaCorreta
                      ? { background: "rgba(235, 64, 52,.1)" }
                      : { background: "rgba(173, 248, 2,.1)" }
                    : {}
                }
                className="alternative-boxEnsinaMais"
              >
                <label
                  htmlFor={index}
                  style={{
                    transition: "all 1s",
                    // marginRight: "100px",
                    // paddingRight: "200px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    // width: "100%",
                    // height: "100%",
                  }}
                >
                  <input
                    disabled={respondeuEsseMode[mode]}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedAlternative(() => {
                        Responder();
                        localStorage.setItem(
                          `${
                            "resposta " +
                            mode +
                            " lessonID " +
                            lesson.idLesson +
                            " userID " +
                            props.userID +
                            " category " +
                            lesson.Questoes[mode - 1].category +
                            " idAtividade " +
                            localStorage.getItem("idAtividade")
                          }`,
                          JSON.stringify({
                            userID: props.userID,
                            resposta: index,
                            questionID: lesson.Questoes[mode - 1].idQuestion,
                            lessonID: lesson.idLesson,
                            category: lesson.Questoes[mode - 1].category,
                            idAtividade: localStorage.getItem("idAtividade"),
                          })
                        );
                        //  console.log("ESTE É O ID LESSON: ", lesson.idLesson)
                        return index;
                      });
                    }}
                    // defaultChecked={false}
                    checked={selectedAlternative === index ? true : false}
                    type="radio"
                    // hidden={true}
                    id={index}
                    value={"alternative" + index}
                  />
                  <div
                    onClick={() => {
                      Responder();
                    }}
                    style={{
                      width: "90%",
                      position: "absolute",
                      height: "100%",
                    }}
                  ></div>

                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {Alternativas(index, qualquer(), alternativa.audio)}

                    <>
                      <p className="alternative-nameEnsinaMais">
                        {ReactHtmlParser(
                          alternativa.alternativeName.toUpperCase()
                        )}
                      </p>
                    </>
                  </div>

                  <div className="box-resposta">{/* X V */}</div>
                </label>
                <AudioButton audio={alternativa.audio}></AudioButton>
              </div>
            </div>
          );
        })}
      <br></br>
      {/* Gabarito: Alternativa {lesson.Questoes[mode - 1].correctAlternative} */}
    </div>
  );
};

export default ObjetivaEnsinaMais;
