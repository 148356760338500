import React from "react";
import img_tela_final_ensinamais from "../../../Files/img_tela_final_ensinamais.png";

const TelaFinalizacaoEnsinaMais = ({ showingSendButton, confirmGeral }) => {
  // console.log(showingSendButton);

  return (
    <div
      style={{
        // marginTop: "-100px",
        // minHeight: "500px",
        // minHeight: "50vh",
        marginTop: "-80px",
      }}
    >
      <div className="tela-finalEnsinaMais">
        <img src={img_tela_final_ensinamais} />

        <div style={{}}>
          <button hidden={!showingSendButton} onClick={() => confirmGeral()}>
            ENVIAR RESPOSTAS
          </button>
        </div>
      </div>

      <div
        style={{
          paddingTop: "100px",
        }}
      ></div>

      {/* <button onClick={() => localStorage.clear()}>Limpar LocalStorage</button> */}
    </div>
  );
};

export default TelaFinalizacaoEnsinaMais;
