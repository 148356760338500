import React from 'react'
import './Importante.css'
const Importante = ({ props }) => {

    const lesson = props.lesson
    const mode = props.mode

    return (
        <div className='balao_importante'>
            <h1>Importante: {lesson.Questoes[mode - 1].importante}</h1>
        </div>
    )
}

export default Importante
