import React, { useEffect, useState } from "react";
import Background from "./components/Background/Background";
import Background_EnsinaMais from "./components/Background/Background_EnsinaMais";
import loadingGif from "./Files/loading.gif";
import Genially from "./pages/Genially/Genially";
import MaoMassa from "./pages/MaoNaMassa/MaoMassa";
import PenseResponda from "./pages/PenseResponda/PenseResponda";
import HoraDoLivro from "./pages/HoraDoLivro/HoraDoLivro";
import TesteConhecimentos from "./pages/TesteConhecimentos/TesteConhecimentos";
import TesteConhecimentosEnsinaMais from "./pages/TesteConhecimentosEnsinaMais/TesteConhecimentosEnsinaMais";
import "./Render.css";
import img_capa_desafio_do_dia from "./Files/cebolinha_balanco.png";
import img_capa_avaliacao_final from "./Files/avaliacao_final_capa_magali.gif";
import img_capa_hora_do_livro from "./Files/tm_alm1_pose_mn_efeito_37.png";
import img_capa_mm from "./Files/img_capa_mm.png";
import img_capa_pr from "./Files/img_capa_pr.png";
import img_capa_tc from "./Files/img_capa_tc.png";

function Render(props) {
  const [tipoAtividade, setTipoAtividade] = useState();
  const [token, setToken] = useState();
  const [showCapa, setShowCapa] = useState(true);
  const [showCapaMaster, setShowCapaMaster] = useState(false);
  const [hideCapa, setHideCapa] = useState(false);
  const [capaMM, setCapaMM] = useState("");
  const [capaPR, setCapaPR] = useState("");
  const [capaTC, setCapaTC] = useState("");
  const [hidenConteudo, setHiddenConteudo] = useState(true);

  const [filtro, setFiltro] = useState(0);

  var root = document.querySelector(":root");
  useEffect(() => {
    setTipoAtividade(props.token.IdAtividade);
    setToken(props.token);

    setCapaMM("612941c50ec5d20dc3cb1ab8");
    setCapaPR("6129551f8449610d46f59dc7");
    setCapaTC("6128da09a7a0f90d9e7ac4de");
  }, [showCapa]);

  setInterval(function () {
    if (!hideCapa) {
      setShowCapa(false);
    }

    var width = window.innerWidth;

    if (!hideCapa) {
      if (width < 1298) {
        setShowCapaMaster(true);
      } else {
      }
    }
  }, 0);

  setInterval(function () {
    setHiddenConteudo(false);
  }, 1000);

  var width, height;
  window.onresize = window.onload = function () {
    width = this.innerWidth;
    height = this.innerHeight;
    // console.log(width)

    if (!hideCapa) {
      if (width < 1298) {
        setShowCapaMaster(true);
      } else {
        setShowCapaMaster(false);
      }
    }
  };

  const turnOnCapa = () => {
    setHideCapa(false);
    setShowCapaMaster(false);
  };

  const turnOffCapa = () => {
    setHideCapa(true);
    setShowCapaMaster(true);
  };

  const setarCor = (cor, bg) => {
    root.style.setProperty("--themeColor", cor);
    root.style.setProperty("--backGroundColor", bg);
  };

  return (
    <div className="Render">
      <div hidden={!hidenConteudo} className="loading-screen">
        <div>
          <img
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
              height: "70px",
              pointerEvents: "none",
            }}
            src={loadingGif}
            alt="loading..."
          />
        </div>
      </div>

      <div hidden={showCapa} className="Geral">
        {tipoAtividade === 1 && (
          <div>
            <Genially
              token={token}
              setarCor={setarCor}
              setFiltro={setFiltro}
            ></Genially>
          </div>
        )}

        {tipoAtividade === 2 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  {/* <iframe
                    className="embed-capa-master"
                    style={{ filter: `${filtro}` }}
                    frameBorder="0"
                    src={`https://view.genial.ly/${capaMM}`}
                    type="text/html"
                  ></iframe> */}
                  <img
                    className="img-anim-capa-geral"
                    style={
                      {
                        // filter: `${filtro}`,
                      }
                    }
                    src={img_capa_mm}
                    alt=""
                  />
                </div>
              </div>
              <MaoMassa
                setFiltro={setFiltro}
                setarCor={setarCor}
                turnOnCapa={turnOnCapa}
                turnOffCapa={turnOffCapa}
                token={token}
              ></MaoMassa>
              {/* <Background></Background> */}
            </div>
          </>
        )}
        {tipoAtividade === 3 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  {/* pointerEvents: 'none',  */}
                  {/* <iframe
                    style={{
                      filter: `${filtro}`,
                      pointerEvents: "none",
                      zIndex: "100",
                      overflow: "hidden",
                      position: "absolute",
                      right: 0,
                      width: "70vw",
                      height: "100vh",
                    }}
                    frameBorder="0"
                    src={`https://view.genial.ly/${capaPR}`}
                    type="text/html"
                  ></iframe> */}
                  <img
                    className="img-anim-capa-geral"
                    style={
                      {
                        // filter: `${filtro}`,
                      }
                    }
                    src={img_capa_pr}
                    alt=""
                  />
                </div>
              </div>
              <PenseResponda
                setFiltro={setFiltro}
                setarCor={setarCor}
                token={token}
              ></PenseResponda>
              {/* <Background></Background> */}
            </div>
          </>
        )}
        {tipoAtividade === 4 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  {/* <iframe
                    style={{
                      filter: `${filtro}`,
                      backgroundColor: "transparent",
                      pointerEvents: "none",
                      zIndex: "100",
                      overflow: "hidden",
                      position: "absolute",
                      right: 190,
                      width: "40vw",
                      height: "100vh",
                    }}
                    frameBorder="0"
                    src={`https://view.genial.ly/${capaTC}`}
                    type="text/html"
                  ></iframe> */}
                </div>
                <img
                  className="img-anim-capa-geral"
                  style={
                    {
                      // filter: `${filtro}`,
                    }
                  }
                  src={img_capa_tc}
                  alt=""
                />
              </div>

              <TesteConhecimentos
                setFiltro={setFiltro}
                setarCor={setarCor}
                turnOnCapa={turnOnCapa}
                turnOffCapa={turnOffCapa}
                token={token}
              ></TesteConhecimentos>
              {/* <Background props={{ img_background_geral }}></Background> */}
            </div>
          </>
        )}
        {tipoAtividade === 5 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  {/* <iframe
                    style={{
                      filter: `${filtro}`,
                      backgroundColor: "transparent",
                      pointerEvents: "none",
                      zIndex: "100",
                      overflow: "hidden",
                      position: "absolute",
                      right: 190,
                      width: "40vw",
                      height: "100vh",
                    }}
                    frameBorder="0"
                    // src={`https://view.genial.ly/${capaTC}`}
                    
                    type="text/html"
                  ></iframe> */}

                  <img
                    className="img-anim-capa-desafio-do-dia"
                    style={
                      {
                        // filter: `${filtro}`,
                      }
                    }
                    src={img_capa_desafio_do_dia}
                    alt=""
                  />
                </div>
              </div>

              <TesteConhecimentosEnsinaMais
                provao={false}
                setFiltro={setFiltro}
                setarCor={setarCor}
                turnOnCapa={turnOnCapa}
                turnOffCapa={turnOffCapa}
                token={token}
              ></TesteConhecimentosEnsinaMais>
              {/* <Background></Background> */}
            </div>
          </>
        )}

        {tipoAtividade === 6 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  {/* <iframe
                    style={{
                      filter: `${filtro}`,
                      backgroundColor: "transparent",
                      pointerEvents: "none",
                      zIndex: "100",
                      overflow: "hidden",
                      position: "absolute",
                      right: 190,
                      width: "40vw",
                      height: "100vh",
                    }}
                    frameBorder="0"
                    // src={`https://view.genial.ly/${capaTC}`}
                    
                    type="text/html"
                  ></iframe> */}

                  <img
                    className="img-anim-capa-avaliacao-final"
                    style={
                      {
                        // filter: `${filtro}`,
                      }
                    }
                    src={img_capa_avaliacao_final}
                    alt=""
                  />
                </div>
              </div>

              <TesteConhecimentosEnsinaMais
                provao={true}
                setFiltro={setFiltro}
                setarCor={setarCor}
                turnOnCapa={turnOnCapa}
                turnOffCapa={turnOffCapa}
                token={token}
              ></TesteConhecimentosEnsinaMais>
              {/* <Background></Background> */}
            </div>
          </>
        )}

        {tipoAtividade === 7 && (
          <>
            <div hidden={hidenConteudo}>
              <div hidden={showCapaMaster} style={{ zIndex: "100000" }}>
                <div hidden={showCapa} id="embed-capa">
                  <img
                    className="img-anim-capa-hora-do-livro"
                    style={
                      {
                        // filter: `${filtro}`,
                      }
                    }
                    src={img_capa_hora_do_livro}
                    alt=""
                  />
                </div>
              </div>
              <HoraDoLivro
                setFiltro={setFiltro}
                setarCor={setarCor}
                token={token}
              ></HoraDoLivro>
              {/* <Background></Background> */}
            </div>
          </>
        )}

        {tipoAtividade === 8 && (
          <div>
            <Genially
              token={token}
              setarCor={setarCor}
              setFiltro={setFiltro}
            ></Genially>
          </div>
        )}


      </div>
    </div>
  );
}

export default Render;
