import Axios from "axios";
import { useBaseURLs } from "./state/Provider";
Axios.defaults.headers.common["Authorization"] = `Bearer "pR889Q6+6CI9VuF"`;

export function BaseURL() {
  const { baseURL } = useBaseURLs();
  return baseURL;
}
const baseURL = BaseURL();

export function getRespostasGabarito(lessonID) {
  return Axios.get(baseURL + `respostas/${lessonID}`, {
    // params: {       /// caso queira passar parametros no body
    //   curso: curso,
    //   aula: aula,
    //   categoria: categoria
    // }
  });
}
export function getCourses(curso, aula, categoria) {
  return Axios.get(baseURL + "test", {
    params: {
      curso: curso,
      aula: aula,
      categoria: categoria,
    },
  });
}

export function getProvao(curso, aula, categoria) {
  return Axios.get(baseURL + "provao", {
    params: {
      curso: curso,
      aula: aula,
      categoria: categoria,
    },
  });
}

export function getPenseResponda(curso, aula) {
  return Axios.get(baseURL + "penseresponda", {
    params: {
      curso: curso,
      aula: aula,
    },
  });
}

export function submitResposta(object) {
  return Axios.post(baseURL + "resposta", object);
}

export function deleteGabarito(userID) {
  return Axios.post(baseURL + `deleteGabarito`, { userID: userID });
}

export function getAnotacoes(urlAnotacoes) {
  return Axios.post(urlAnotacoes);
}

export function validate(questionID, userID) {
  return Axios.get(baseURL + "validate", {
    params: {
      questionID: questionID,
      userID: userID,
    },
  });
}
export function authToken(token) {
  return Axios.post(baseURL + "auth", token);
}
export function listarAnotacoes(url, body) {
  return Axios.post(url, body, {
    auth: {
      username: "TeleSapiens",
      password: "T&leS@p13n$",
    },
  });
}
export function inserirAnotacoes(url, body) {
  return Axios.post(url, body, {
    auth: {
      username: "TeleSapiens",
      password: "T&leS@p13n$",
    },
  });
}
export function alterarAnotacoes(url, body) {
  return Axios.post(url, body, {
    auth: {
      username: "TeleSapiens",
      password: "T&leS@p13n$",
    },
  });
}
