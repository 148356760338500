import React from "react";
import "./Title.css";

const Title = ({ props }) => {
  const courseName = props.courseName;
  const lesson = props.lesson;
  return (
    <header className="title">
      {courseName} - {lesson.lessonName}
    </header>
  );
};

export default Title;

